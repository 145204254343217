export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const toArrayBuffer = (
  file: File,
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const downloadPdf = (objectUrl: string, name: string) => {
  // const objectUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  // const namePDF =
  //   name?.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase() === "pdf"
  //     ? name
  //     : `${name}.pdf`;
  a.href = objectUrl;
  a.style.display = "none";
  a.download = `${name}.pdf`;
  a.setAttribute("download", `${name}.pdf`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;

  return theBlob as File;
};

export const removeExtension = (text?: string) =>
  text?.replace(/(\.[^/.]+)$/, "") || "";

export const dataURItoBlob = (dataURI: string): Blob => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString: string;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(dataURI.split(",")[1]);
  } else {
    byteString = decodeURIComponent(dataURI.split(",")[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const byteArray = new Uint8Array(byteString.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([byteArray], { type: mimeString });
};
