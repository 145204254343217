import { FC } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { Icon } from "components/UI";
import { palette, cs, getMenuTitle } from "utils";
import { MenuItem, SigningsType, TSharedDocumentStatus } from "types";
import { useActions } from "hooks";

import styles from "./styles.module.scss";

type ContextMenuProps = {
  onClose?: () => void;
  items: MenuItem[];
  theme: "dark" | "light";
  top?: number;
  left?: number;
  isFixed?: boolean;
  isTrash?: boolean;
  isContact?: boolean;
  id: string;
  signingsType?: SigningsType;
  signingsStatus?: TSharedDocumentStatus;
  isViewHistory?: boolean;
};

export const ContextMenu: FC<ContextMenuProps> = ({
  onClose = () => {},
  items,
  theme,
  top = "auto",
  left = "auto",
  isFixed,
  isTrash,
  isContact,
  id,
  signingsType,
  signingsStatus,
  isViewHistory,
}) => {
  const { getAction } = useActions(id);

  const handleClick = (el: MenuItem) => {
    onClose();
    getAction({
      name: el,
      isTrash,
      isContact,
      signingsType,
      signingsStatus,
      isViewHistory,
    })();
  };

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div
        className={cs([styles.ContextMenu, theme === "light" && styles.light])}
        style={{
          position: isFixed ? "fixed" : "absolute",
          top: `${top}px`,
          left: `${left}px`,
          right: isFixed ? "auto" : "10px",
        }}
      >
        <ul className={styles.menuList}>
          {items.map((el, index) => (
            <li
              key={index}
              className={cs([
                styles.menuItem,
                theme === "light" && styles.light,
              ])}
              onClick={() => handleClick(el)}
            >
              <Icon
                name={el}
                size={14}
                color={theme === "light" ? palette.black : palette.grey50}
                // action={getAction(el)}
              />
              {getMenuTitle({
                name: el,
                isTrash,
                isContact,
                signingsType,
                signingsStatus,
                isViewHistory,
              })}
            </li>
          ))}
        </ul>
      </div>
    </OutsideClickHandler>
  );
};
