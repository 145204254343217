import { FC, useEffect, useState, useCallback, useContext } from "react";
import { Instance } from "pspdfkit";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";

import { Button } from "components/UI";

import { useInstance } from "hooks";
import { cs, palette, getPointerTitle } from "utils";
import { ThemeContext } from "providers";

import styles from "./styles.module.scss";

type GoNextButtonProps = {
  stateInstance: Instance | null;
  statePSPDFKit: any;
  isFooter?: boolean;
  stateChanged?: boolean;
  // fromTop?: number;
  isSigningStarted?: boolean;
  setIsSigningStarted?: (value: boolean) => void;
};

export const GoNextButton: FC<GoNextButtonProps> = ({
  stateInstance,
  statePSPDFKit,
  isFooter,
  stateChanged,
  isSigningStarted,
  setIsSigningStarted = () => {},
  // fromTop = 0,
}) => {
  const { t } = useTranslation("RecipientSigningFooter");
  const dispatch = useAppDispatch();
  const { isMobile } = useContext(ThemeContext);
  // const isSigningStarted = sessionStorage.getItem("IsSigningStarted");
  const {
    getUnsignedWidgets,
    getSignedWidgets,
    getTotalWidgetsCount,
    zoomIndex,
  } = useInstance();
  // const [unsignedWidgetIndex, setUnsignedWidgetIndex] = useState<number>(0);
  const [totalWidgets, setTotalWidgets] = useState<number>(0);
  const [totalSigned, setTotalSigned] = useState<number>(0);
  const [fromTop, setFromTop] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isGoNext, setGoNext] = useState<boolean>(false);
  const [widgetType, setWidgetType] = useState<string>("");

  const handleGoNext = useCallback(async () => {
    if (stateInstance) {
      const allWidgets = await getUnsignedWidgets({
        instance: stateInstance,
        PSPDFKit: statePSPDFKit,
      });
      const allFormFieldValues = stateInstance.getFormFieldValues();
      const unsignedWidgets: any[] = [];
      await Promise.all(
        allWidgets.map(async (el) => {
          const isOverLapped =
            await stateInstance.getOverlappingAnnotations(el);
          const formFieldName = allFormFieldValues[el.formFieldName];
          if (
            isOverLapped.size === 0 && el.formFieldName
              ? (Array.isArray(formFieldName) && formFieldName[0] === "Off") ||
                allFormFieldValues[el.formFieldName] === "" ||
                el.formFieldName?.startsWith("SIGNATURE")
              : true
          ) {
            unsignedWidgets.push(el);
          }
        }),
      );

      const sortedWidgets = [...unsignedWidgets].sort((a, b) => {
        if (a.pageIndex === b.pageIndex) {
          return a.boundingBox.top - b.boundingBox.top;
        }
        return a.pageIndex - b.pageIndex;
      });

      if (sortedWidgets[0]) {
        if (isGoNext || isFooter) {
          setWidgetType(sortedWidgets[0].formFieldName);
          stateInstance.jumpAndZoomToRect(
            sortedWidgets[0].pageIndex,
            sortedWidgets[0].boundingBox,
          );
          stateInstance.setViewState((state) => state.set("zoom", zoomIndex));
          if (sortedWidgets[0]?.boundingBox) {
            setFromTop(
              sortedWidgets[0].boundingBox.top * zoomIndex +
                sortedWidgets[0].boundingBox.height / 2 || 0,
            );
            setCurrentPage(sortedWidgets[0].pageIndex);
          }

          // setUnsignedWidgetIndex((prevState) =>
          //   prevState < unsignedWidgets.length - 1 ? prevState + 1 : 0,
          // );
          setGoNext(false);
        }
        // else {
        //   signSignatureField({
        //     instance: stateInstance,
        //     PSPDFKit: statePSPDFKit,
        //     annotation:
        //       unsignedWidgets[
        //         unsignedWidgetIndex - 1 < 0
        //           ? unsignedWidgets.length - 1
        //           : unsignedWidgetIndex - 1
        //       ],
        //   });
        //   setGoNext(true);
        // }
      }
    }
  }, [
    getUnsignedWidgets,
    isGoNext,
    // signSignatureField,
    stateInstance,
    statePSPDFKit,
    // unsignedWidgetIndex,
    isFooter,
    zoomIndex,
  ]);

  const initTotalWidgets = useCallback(async () => {
    if (stateInstance) {
      const totalWidgetsCount = await getTotalWidgetsCount({
        instance: stateInstance,
        PSPDFKit: statePSPDFKit,
      });

      setTotalWidgets(totalWidgetsCount);
    }
  }, [getTotalWidgetsCount, stateInstance, statePSPDFKit]);

  const initTotalSigned = useCallback(async () => {
    if (stateInstance) {
      const signedWidgets = await getSignedWidgets({
        instance: stateInstance,
      });

      setTotalSigned(signedWidgets || 0);
    }
  }, [getSignedWidgets, stateInstance]);

  const initTotalUnsigned = useCallback(async () => {
    if (isSigningStarted) {
      handleGoNext();
    }
  }, [handleGoNext, isSigningStarted]);

  useEffect(() => {
    initTotalUnsigned();
  }, [initTotalUnsigned]);

  useEffect(() => {
    initTotalWidgets();
  }, [initTotalWidgets]);

  useEffect(() => {
    initTotalSigned();
  }, [initTotalSigned, stateChanged]);

  useEffect(() => {
    setGoNext(true);
  }, [totalSigned]);

  useEffect(() => {
    const zoomInstance =
      stateInstance?.contentDocument.querySelectorAll(".PSPDFKit-Spread");
    const oldPointer =
      stateInstance?.contentDocument.querySelector<HTMLElement>(
        "#documentWidgetPointer",
      );
    oldPointer && oldPointer.parentNode?.removeChild(oldPointer);
    const pageInstance = zoomInstance && zoomInstance[currentPage];
    if (pageInstance) {
      const buttonEl = document.createElement("div");
      buttonEl.id = "documentWidgetPointer";
      buttonEl.style.position = "absolute";
      buttonEl.style.left = isMobile ? "-60px" : "-130px";
      buttonEl.style.top = `0px`;
      buttonEl.style.width = isMobile ? "60px" : "130px";
      buttonEl.style.height = isMobile ? "20px" : "40px";
      buttonEl.style.backgroundColor = palette.actionGreen;
      buttonEl.style.borderRadius = "5px 0 0 5px";
      buttonEl.style.color = palette.black;
      buttonEl.style.zIndex = "102";
      buttonEl.style.fontSize = isMobile ? "12px" : "18px";
      buttonEl.style.textAlign = "center";
      buttonEl.style.display = "flex";
      buttonEl.style.justifyContent = "center";
      buttonEl.style.alignItems = "center";
      buttonEl.style.fontFamily = "Clash";
      buttonEl.innerHTML = isMobile ? "" : "Start";
      buttonEl.style.pointerEvents = "all";
      buttonEl.addEventListener(
        "pointerdown",
        () => {
          sessionStorage.setItem("IsSigningStarted", "true");
          setIsSigningStarted(true);
          handleGoNext();
        },
        {
          capture: true,
        },
      );

      pageInstance.appendChild(buttonEl);
    }
  }, [
    stateInstance,
    currentPage,
    handleGoNext,
    totalSigned,
    totalWidgets,
    dispatch,
    setIsSigningStarted,
    isMobile,
  ]);

  useEffect(() => {
    const pointer = stateInstance?.contentDocument.querySelector<HTMLElement>(
      "#documentWidgetPointer",
    );
    if (pointer) {
      pointer.style.top = `${fromTop}px`;

      // if (!isMobile) {
      pointer.innerHTML = isSigningStarted
        ? getPointerTitle(widgetType)
        : t("start");
      // }

      if (isSigningStarted) {
        const triangleEl = document.createElement("div");
        triangleEl.style.position = "absolute";
        triangleEl.style.top = "0";
        triangleEl.style.right = isMobile ? "-10px" : "-20px"; // Adjust as needed to align the triangle
        triangleEl.style.width = "0";
        triangleEl.style.height = "0";
        triangleEl.style.borderTop = `${
          isMobile ? 10 : 20
        }px solid transparent`;
        triangleEl.style.borderBottom = `${
          isMobile ? 10 : 20
        }px solid transparent`;
        triangleEl.style.borderLeft = `${isMobile ? 10 : 20}px solid ${
          palette.actionGreen
        }`; // Match the button background color

        pointer.appendChild(triangleEl);
      }
      // Create a triangle div
    }
    if (pointer && totalSigned > 0 && totalSigned === totalWidgets) {
      pointer.parentNode?.removeChild(pointer);
    }
  }, [
    fromTop,
    stateInstance,
    isSigningStarted,
    t,
    totalSigned,
    totalWidgets,
    handleGoNext,
    widgetType,
    isMobile,
  ]);

  return (
    <div
      className={cs([styles.GoNextButton, isFooter && styles.relative])}
      // style={{ top: `100px` }}
    >
      <div className={styles.wrap}>
        {isFooter && (
          <Button
            title={t("goToNext")}
            // title={
            //   isGoNext || isFooter
            //     ? t("goToNext")
            //     : `${t("sign")} (${totalSigned}/${totalUnsigned})`
            // }
            onClick={handleGoNext}
            variant="primary"
          />
        )}
        {/* {!isFooter && (
          <p className={styles.textInfo}>
            Signed {`(${totalSigned}/${totalUnsigned})`}
          </p>
        )} */}
      </div>
    </div>
  );
};
