import { FC, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { SharedDocuments } from "api";
import { useAppSelector, dashboard } from "store";

import { IActionHistoryItem } from "types";

import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type ActionsHistoryModalProps = {
  onClose: () => void;
  id: string;
  docName: string;
  isGuest?: boolean;
};

export const ActionsHistoryModal: FC<ActionsHistoryModalProps> = ({
  onClose,
  id,
  docName,
  isGuest,
}) => {
  const { dateFormat, timeFormat } = useAppSelector(dashboard);
  const { t } = useTranslation("Modals", { keyPrefix: "ActionsHistory" });
  const [actions, setActions] = useState<IActionHistoryItem[]>([]);

  const initActions = useCallback(async () => {
    if (id) {
      let res;
      if (isGuest) {
        res = await SharedDocuments.getGuestActionsHistory({
          id,
        });
      } else {
        res = await SharedDocuments.getActionsHistory({
          id,
        });
      }

      if (res?.items) {
        setActions(res?.items);
      }
    }
  }, [id, isGuest]);

  useEffect(() => {
    initActions();
  }, [initActions]);

  return (
    <div className={styles.ActionsHistoryModal}>
      <ModalHeader onClose={onClose} title={t("title")} info={docName} />
      <ul className={styles.actionsList}>
        {actions.map((el) => (
          <li key={el.participant.email} className={styles.action}>
            <div className={styles.title}>{el.action?.toLocaleLowerCase()}</div>
            <div className={styles.info}>
              <div>
                {dayjs(el.createdAt).format(`${dateFormat}, ${timeFormat}`)}
              </div>
              <div>{el.participant.name}</div>
              <div>{el.participant.email}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
