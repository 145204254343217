import { useState, useEffect, useCallback, FC } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, signatures } from "store";

import { Icon } from "components/UI";

import { cs } from "utils";
import { useInstance } from "hooks";

import styles from "./styles.module.scss";

type SignatureWidgetProps = {
  onChangeSignature?: (isInitial?: boolean) => void;
};

export const SignatureWidget: FC<SignatureWidgetProps> = ({
  onChangeSignature = () => {},
}) => {
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "SignatureWidget",
  });
  const { isDeleted, isCreated, isUpdated } = useAppSelector(signatures);
  const { getDefaultSignatureImage } = useInstance();
  // const { attachmentsArray } = useAppSelector(signatures);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [isResizer, setIsResizer] = useState<boolean>(false);
  const [signatureImageUrl, setSignatureImageUrl] = useState<string>("");
  const [initialsImageUrl, setInitialsImageUrl] = useState<string>("");

  const initDefaultSignatureImage = useCallback(async () => {
    const defImage = await getDefaultSignatureImage();
    setSignatureImageUrl(defImage);
  }, [getDefaultSignatureImage]);

  const initDefaultInitialsImage = useCallback(async () => {
    const defImage = await getDefaultSignatureImage(true);
    setInitialsImageUrl(defImage);
  }, [getDefaultSignatureImage]);

  useEffect(() => {
    initDefaultSignatureImage();
    initDefaultInitialsImage();
  }, [
    initDefaultSignatureImage,
    initDefaultInitialsImage,
    isDeleted,
    isCreated,
    isUpdated,
  ]);

  return (
    <nav
      className={cs([styles.SignatureWidget, isMinimized && styles.minimized])}
    >
      <div
        className={cs([styles.resizer, isResizer && styles.visible])}
        onClick={() => setIsMinimized((prevState) => !prevState)}
        onMouseEnter={() => setIsResizer(true)}
        onMouseLeave={() => setIsResizer(false)}
      >
        <Icon
          name="chevron-left-empty"
          size={20}
          className={cs([styles.arrowIcon, isMinimized && styles.rotated])}
        />
      </div>
      <h2 className={styles.title}>{t("title")}:</h2>
      <div className={styles.signBoxes}>
        <div className={styles.signBox} onClick={() => onChangeSignature()}>
          {signatureImageUrl ? (
            <img src={signatureImageUrl} alt="" className={styles.signImage} />
          ) : (
            <div className={styles.signPlaceholder}>{t("addSignature")}</div>
          )}

          {/* <div className={styles.changeButton} onClick={onChangeSignature}>
          {t("change")}
        </div> */}
        </div>
        <div className={styles.signBox} onClick={() => onChangeSignature(true)}>
          {initialsImageUrl ? (
            <img src={initialsImageUrl} alt="" className={styles.signImage} />
          ) : (
            <div className={styles.signPlaceholder}>{t("addInitials")}</div>
          )}
        </div>
      </div>
      {/* <div className={styles.buttons}>
        <Button
          variant="secondary"
          title={t("signButtonTitle")}
          onClick={handleSignAll}
          className={styles.submitButton}
        />
        <Button
          variant="secondary"
          title={t("cleanButtonTitle")}
          onClick={handleCleanAll}
          className={styles.cancelButton}
        />
      </div> */}
    </nav>
  );
};
