import { FC, useEffect, useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Annotation, Instance } from "pspdfkit";
import OutsideClickHandler from "react-outside-click-handler";
import useWebSocket from "react-use-websocket";

import { useAppDispatch, useAppSelector, signings } from "store";
import { ThemeContext } from "providers";
import {
  setInboxDoc,
  setIsInboxRejectRequestModal,
  setGuestRecipientDoc,
} from "store/signings";
import { SharedDocuments } from "api";

import { RejectSigningModal } from "components";
import { Button, Modal, Spinner } from "components/UI";

import { PATHES } from "constants/pathes";
import { WS_URL } from "constants/index";

import { toastSuccess, cs, sleep, palette } from "utils";
import { useActions, useInstance } from "hooks";

// import { GoNextButton } from "../GoNextButton";
import styles from "./styles.module.scss";

type RecipientSigningFooterProps = {
  stateChanged: boolean;
  stateInstance: Instance | null;
  statePSPDFKit: any;
  handleSignAll?: () => void;
  handleCleanAll?: () => void;
  totalSigned: number;
  totalWidgets: number;
  isSignAllDisabled: boolean;
  isFooter: boolean;
  setIsFooter: (value: boolean) => void;
};

export const RecipientSigningFooter: FC<RecipientSigningFooterProps> = ({
  stateChanged,
  stateInstance,
  statePSPDFKit,
  handleSignAll = () => {},
  handleCleanAll = () => {},
  totalSigned,
  totalWidgets,
  isSignAllDisabled,
  isFooter,
  setIsFooter,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("RecipientSigningFooter");
  const { t: tS } = useTranslation("RequestSigning", {
    keyPrefix: "SignatureWidget",
  });
  const { t: tH } = useTranslation("RecipientSigningHeader");
  // const [isFooter, setIsFooter] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFullyCompleted, setIsFullyCompleted] = useState<boolean>(false);
  const [isMobileMenu, setIsMobileMenu] = useState<boolean>(false);
  const { inboxDoc, guestRecipientDoc, isInboxRejectRequestModal } =
    useAppSelector(signings);
  const navigate = useNavigate();
  const { linkId } = useParams();
  const { isMobile } = useContext(ThemeContext);
  const { handleRejectRequest } = useActions(guestRecipientDoc?.id || "");
  const { getDefaultSignatureImage } = useInstance();

  const checkIfFilledWithSignatures = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      const totalPages = stateInstance?.totalPageCount;

      let widgetsSignaturesToSignCount = 0;
      let widgetsInitialsToSignCount = 0;
      let widgetsDateCount = 0;
      let widgetsCheckboxCount = 0;
      let widgetsTextCount = 0;

      let signaturesCount = 0;
      let initialsCount = 0;

      const pageIndexes = Array.from(Array(totalPages).keys());
      const allFormFieldValues = stateInstance.getFormFieldValues();

      try {
        if (pageIndexes.length > 0 && statePSPDFKit && stateInstance) {
          await Promise.all(
            pageIndexes.map(async (page) => {
              try {
                const allAnnotations =
                  stateInstance.getAnnotations &&
                  (await stateInstance.getAnnotations(page));

                const widgetsSignaturesToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_WIDGET"),
                );
                const widgetsInitialsToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_INITIALS"),
                );
                const widgetsDate = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("DATE_WIDGET"),
                );
                const widgetsCheckbox = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("CHECKBOX_WIDGET"),
                );
                const widgetsText = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("TEXT_WIDGET"),
                );

                const signatures = allAnnotations.filter((el: Annotation) => {
                  return el.isSignature && !el.customData?.isInitial;
                });
                const initials = allAnnotations.filter(
                  (el: Annotation) =>
                    el.isSignature && el.customData?.isInitial,
                );

                // Increment counts
                widgetsSignaturesToSignCount += widgetsSignaturesToSign.size;
                widgetsInitialsToSignCount += widgetsInitialsToSign.size;
                widgetsDateCount += widgetsDate.size;
                widgetsCheckboxCount += widgetsCheckbox.size;
                widgetsTextCount += widgetsText.size;

                signaturesCount += signatures.size;
                initialsCount += initials.size;
              } catch (error) {
                console.log("error:", error);
              }
            }),
          );
        }
      } catch (error) {
        console.log("error:", error);
      }
      const isAllSignaturesFilled =
        widgetsSignaturesToSignCount > 0
          ? signaturesCount === widgetsSignaturesToSignCount
          : true;
      const isAllInitialsFilled =
        widgetsInitialsToSignCount > 0
          ? initialsCount === widgetsInitialsToSignCount
          : true;
      const isAllDateFilled =
        widgetsDateCount > 0
          ? Object?.entries(allFormFieldValues)
              ?.filter((el: any) => el[0].startsWith("DATE_WIDGET"))
              .filter((el) => el[1] && el[1][0] !== "")?.length ===
            widgetsDateCount
          : true;
      const isAllCheckboxFilled =
        widgetsCheckboxCount > 0
          ? Object?.entries(allFormFieldValues)
              ?.filter((el: any) => el[0].startsWith("CHECKBOX_WIDGET"))
              .filter((el) => el[1] && el[1][0] !== "Off")?.length ===
            widgetsCheckboxCount
          : true;
      const allTextWidgets = Object?.entries(allFormFieldValues)?.filter(
        (el: any) => el[0].startsWith("TEXT_WIDGET"),
      );
      const allCheckboxWidgets = Object?.entries(allFormFieldValues)?.filter(
        (el: any) => el[0].startsWith("CHECKBOX_WIDGET"),
      );
      const isAllTextFilled =
        widgetsTextCount > 0
          ? allTextWidgets?.filter((el: any) => el[1] && el[1][0] !== "")
              ?.length === widgetsTextCount
          : true;
      if (isAllSignaturesFilled && isAllInitialsFilled && isAllDateFilled) {
        setIsFooter(true);
        setIsFullyCompleted(
          allTextWidgets.length > 0 || allCheckboxWidgets
            ? isAllTextFilled && isAllCheckboxFilled
            : true,
        );
      } else {
        setIsFooter(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateInstance, statePSPDFKit, stateChanged]);

  const handleFinish = async () => {
    const savedInstant = localStorage.getItem("instantJSON");
    const parsedInstant = savedInstant && JSON.parse(savedInstant);
    const defSignatureImage = await getDefaultSignatureImage();
    const defInitialImage = await getDefaultSignatureImage(true);
    const defImage = defSignatureImage || defInitialImage;
    if (isFooter && parsedInstant && inboxDoc?.id && defImage) {
      const res = await SharedDocuments.signInboxDoc(inboxDoc.id, {
        instantJson: parsedInstant,
        signatureFile: defImage,
      });
      if (res) {
        setIsLoading(true);
        await sleep(10000);
        setIsLoading(false);
        navigate(PATHES.INBOX);
        toastSuccess(t("toastSuccess"));
      }
    }
    if (
      isFooter &&
      parsedInstant &&
      guestRecipientDoc?.id &&
      linkId &&
      defImage
    ) {
      const res = await SharedDocuments.signGuestRecipientDoc(linkId, {
        instantJson: parsedInstant,
        signatureFile: defImage,
      });
      if (res) {
        setIsLoading(true);
        await sleep(10000);
        setIsLoading(false);
        navigate(`${PATHES.GUEST_RECIPIENT_DOCUMENT_READY}/${linkId}`);
      }
    }
  };

  useEffect(() => {
    checkIfFilledWithSignatures();
  }, [checkIfFilledWithSignatures]);

  const handleOpenMobileMenu = () => {
    setIsMobileMenu(true);
  };

  const handleCloseMobileMenu = () => {
    setIsMobileMenu(false);
  };

  const handleRejectDocument = () => {
    if (guestRecipientDoc?.id) {
      handleRejectRequest();
      handleCloseMobileMenu();
    }
  };

  const handleSubmitReject = () => {
    dispatch(setIsInboxRejectRequestModal(false));
    localStorage.removeItem("instantJSON");
    dispatch(setInboxDoc(null));
    dispatch(setGuestRecipientDoc(null));
    navigate(PATHES.INBOX_VOIDED);
    toastSuccess(tH("toastRejectSuccess"));
  };

  const handleCloseRejectModal = () => {
    dispatch(setIsInboxRejectRequestModal(false));
  };

  const handleMessage = async (message: any) => {
    const { data } = (message.data && JSON.parse(message.data)) || {};
    console.log("data?.type:", data?.type);
    if (data?.type === "signaturesMergeSuccess") {
      if (linkId) {
        setIsLoading(false);
        navigate(`${PATHES.GUEST_RECIPIENT_DOCUMENT_READY}/${linkId}`);
      }
    }
  };

  useWebSocket(process.env.REACT_APP_WS_URL || WS_URL, {
    queryParams: { Authorization: linkId || "" },
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 3,
    reconnectInterval: 1000,
    onOpen: () => console.log("ws connected"),
    onError: (error) => {},
    onMessage: handleMessage,
  });

  const renderMobileMenu = () => {
    if (isMobile) {
      return (
        <OutsideClickHandler onOutsideClick={handleCloseMobileMenu}>
          <div className={styles.mobileMenuWrap}>
            <Button
              title=""
              onClick={handleOpenMobileMenu}
              variant="outlinedBlack"
              size="lg"
              className={styles.menuButton}
              iconStart="three-dots-horizontal"
            />
            {isMobileMenu && (
              <div className={styles.mobileMenu}>
                <Button
                  variant="textBlack"
                  title={tS("signButtonTitle")}
                  onClick={() => {
                    handleSignAll();
                    handleCloseMobileMenu();
                  }}
                  isDisabled={isSignAllDisabled}
                />
                <Button
                  variant="textBlack"
                  title={tS("cleanButtonTitle")}
                  onClick={() => {
                    handleCleanAll();
                    handleCloseMobileMenu();
                  }}
                  isDisabled={totalSigned === 0}
                />
                <Button
                  title={tH("rejectButtonTitle")}
                  size="md"
                  variant="textBlack"
                  onClick={handleRejectDocument}
                  className={styles.redButton}
                />
              </div>
            )}
          </div>
        </OutsideClickHandler>
      );
    }
  };

  return (
    <>
      <Modal isShowed={isInboxRejectRequestModal}>
        <RejectSigningModal
          onClose={handleCloseRejectModal}
          onSubmit={handleSubmitReject}
        />
      </Modal>
      {(isFooter || isMobile) && (
        <footer
          className={cs([
            styles.RecipientSigningFooter,
            isFullyCompleted && styles.completed,
            isMobile && styles.mobile,
          ])}
        >
          {/* {isFullyCompleted ? (
            <> */}
          {/* <div className={styles.completedText}>
            <p className={cs([styles.text, isMobile && styles.mobile])}>
              {t("completedText")}
            </p>
            <p
              className={cs([styles.additionalText, isMobile && styles.mobile])}
            >
              {t("additionalComletedText")}
            </p>
          </div> */}

          <div className={styles.buttonsWrap}>
            {isMobile && !isFooter && (
              <Button
                title={`Signed ${totalSigned}/${totalWidgets}`}
                onClick={() => {}}
                variant="primary"
                size="lg"
                className={styles.signed}
              />
            )}
            {isFooter && (
              <Button
                title={
                  isLoading ? (
                    <Spinner color={palette.white} />
                  ) : (
                    t("submitButtonTitle")
                  )
                }
                onClick={handleFinish}
                variant="primary"
                size="lg"
                className={cs([
                  styles.singleButton,
                  isFullyCompleted && styles.completed,
                ])}
              />
            )}
            {renderMobileMenu()}
          </div>
          {/* </>
          ) : (
            <>
              <p className={cs([styles.text, isMobile && styles.mobile])}>
                {t("requiredText")}
              </p>
              <div className={styles.buttonsWrap}>
                <GoNextButton
                  stateInstance={stateInstance}
                  statePSPDFKit={statePSPDFKit}
                  isFooter
                />
                <Button
                  title={
                    isLoading ? (
                      <Spinner color={palette.black} />
                    ) : (
                      t("submitButtonTitle")
                    )
                  }
                  onClick={handleFinish}
                  variant="outlinedBlack"
                  size="lg"
                  className={styles.pairButton}
                />
                {renderMobileMenu()}
              </div>
            </>
          )} */}
        </footer>
      )}
    </>
  );
};
