/* eslint-disable no-underscore-dangle */
import {
  FC,
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Annotation, Instance } from "pspdfkit";
import fileDownload from "js-file-download";
import axios from "axios";
import { useParams } from "react-router-dom";

import { setStoredInstance, setIsUpdated } from "store/signatures";
import { setIsDetailedViewMenu } from "store/signings";
import {
  useAppDispatch,
  useAppSelector,
  requestSigning,
  signings,
  settings,
  signatures,
} from "store";
import { ThemeContext } from "providers";

import { SharedDocuments, Signatures } from "api";
import {
  toArrayBuffer,
  palette,
  getEmptyNode,
  // getButtonNode,
  // signedStatusNode,
  sleep,
} from "utils";
import { useInstance, useUploadRequest, useActions } from "hooks";

import downloadIcon from "assets/img/icons/download.svg";
import moveFolderIcon from "assets/img/pdfView/moveFolder.svg";
import printIcon from "assets/img/pdfView/print.svg";
// import signIcon from "assets/img/pdfView/sign.svg";
// import eraseIcon from "assets/img/pdfView/erase.svg";

import { RecipientsWidget } from "./RecipientsWidget";
import { SignatureWidget } from "./SignatureWidget";
import { RejectionWidget } from "./RejectionWidget";
import { RecipientSigningFooter } from "./Footer";
import { GoNextButton } from "./GoNextButton";
import { CounterInfo } from "./CounterInfo";

export const PdfViewerRecipientSigning: FC = () => {
  const dispatch = useAppDispatch();
  const { activeRecipient } = useAppSelector(requestSigning);
  const { user } = useAppSelector(settings);
  const { inboxDoc, sentDoc, guestRecipientDoc, guestOwnerDoc } =
    useAppSelector(signings);
  const {
    allSignatures: allStoredSignatures,
    allGuestSignatures: allStoredGuestSignatures,
  } = useAppSelector(signatures);
  const storageisSigningStarted =
    !!sessionStorage.getItem("IsSigningStarted") || false;
  const { handleMoveInboxDocument } = useActions(inboxDoc?.id);
  const { handleMoveSentDocument } = useActions(sentDoc?.id);
  const {
    saveInkAnnotationAtachment,
    saveInstanceJSON,
    saveSignatures,
    deleteSignature,
    getSavedJSON,
    signAllSignatureFields,
    signAllFormFields,
    cleanAllSignatureFields,
    cleanChangedFields,
    signSignatureField,
    // getSignedWidgets,
    // getTotalWidgetsCount,
    getIsSignAllDisabled,
    zoomIndex,
  } = useInstance();
  const recipientId = activeRecipient?.email || "";
  const [onPageIndex, setOnPageIndex] = useState<number>(0);
  const [stateInstance, setStateInstance] = useState<Instance | null>(null);
  const [statePSPDFKit, setStatePSPDFKit] = useState<any>(null);
  const [stateChanged, setStateChanged] = useState<boolean>(false);
  // const [annotationsChanged, setAnnotationsChanged] = useState<boolean>(false);
  const [isFooter, setIsFooter] = useState<boolean>(false);
  const [isSigningStarted, setIsSigningStarted] = useState<boolean>(
    storageisSigningStarted,
  );
  const [totalWidgets, setTotalWidgets] = useState<number>(0);
  const [totalSigned, setTotalSigned] = useState<number>(0);
  const [storedSignatures, setStoredSignatures] = useState<any[]>([]);
  const [storedAttachments, setStoredAttachments] = useState<any[]>([]);
  const [storedSignaturesInitialized, setStoredSignaturesInitialized] =
    useState<boolean>(false);
  const [isSignAllDisabled, setIsSignAllDisabled] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const onPageIndexRef = useRef(onPageIndex);
  onPageIndexRef.current = onPageIndex;
  const isCreateInitial = useRef<boolean>(false);
  const isToDeleteSignature = useRef<any>(null);
  const { handleAddWaterMark } = useUploadRequest();
  const isLocal = window.location.href.startsWith("http://localhost:");
  const { isMobile } = useContext(ThemeContext);
  const { inboxDocId, sentDocId, linkId, ownerLinkId } = useParams();
  const allSignatures = linkId ? allStoredGuestSignatures : allStoredSignatures;
  let docToSign = inboxDoc;
  if (sentDocId) {
    docToSign = sentDoc;
  } else if (linkId) {
    docToSign = guestRecipientDoc;
  } else if (ownerLinkId) {
    docToSign = guestOwnerDoc;
  }

  const handleChangeSignature = useCallback(
    async (isInitial?: boolean) => {
      isCreateInitial.current = false;
      if (isInitial) {
        isCreateInitial.current = true;
      }
      isToDeleteSignature.current = true;
      // const storedSignatures = inboxDocId
      //   ? await getStoredSignatures(isCreateInitial.current)
      //   : [];
      const filteredList = statePSPDFKit.Immutable?.List(
        storedSignatures
          ?.filter((el) => el)
          ?.filter((el) =>
            isCreateInitial.current
              ? el?.customData?.isInitial
              : !el?.customData?.isInitial,
          )
          ?.map(statePSPDFKit.Annotations.fromSerializableObject),
      );
      stateInstance?.setStoredSignatures(filteredList);
      stateInstance?.setViewState((viewState: any) =>
        viewState.set(
          "interactionMode",
          statePSPDFKit.InteractionMode.SIGNATURE,
        ),
      );
    },
    [statePSPDFKit, stateInstance, storedSignatures],
  );

  // const initTotalSigned = useCallback(async () => {
  //   if (stateInstance) {
  //     const signedWidgets = await getSignedWidgets({
  //       instance: stateInstance,
  //     });

  //     setTotalSigned(signedWidgets || 0);
  //   }
  // }, [getSignedWidgets, stateInstance]);

  // const initSignatures = useCallback(async () => {
  //   if (stateInstance) {
  //     const newStoredSignatures = await getStoredSignatures(
  //       isCreateInitial?.current,
  //     );
  //     if (
  //       newStoredSignatures &&
  //       newStoredSignatures.length > 0 &&
  //       !storedSignaturesInitialized
  //     ) {
  //       setStoredSignatures(newStoredSignatures);
  //       setStoredSignaturesInitialized(true);
  //     }
  //   }
  // }, [
  //   // allSignatures.length,
  //   getStoredSignatures,
  //   stateInstance,
  //   isCreateInitial,
  //   storedSignaturesInitialized,
  // ]);

  // const initAtachments = useCallback(async () => {
  //   if (stateInstance) {
  //     const newStoredAttachments = await getStoredAtachments(
  //       isCreateInitial?.current,
  //     );
  //     if (
  //       newStoredAttachments &&
  //       newStoredAttachments.length > 0 &&
  //       !storedAttachmentsInitialized
  //     ) {
  //       setStoredAttachments(newStoredAttachments);
  //       await sleep(1000);
  //       setStoredAttachmentsInitialized(true);
  //     }
  //   }
  // }, [
  //   getStoredAtachments,
  //   stateInstance,
  //   isCreateInitial,
  //   storedAttachmentsInitialized,
  // ]);

  const initSignatures = useCallback(async () => {
    const res = await Promise.all(
      allSignatures.map(async (item) => {
        try {
          const { data } = await axios.get(item.url);
          return {
            ...data,
            customData: {
              ...data?.customData,
              savedSignatureId: item.id,
              isSignatureDefault: item.isDefault,
            },
          };
        } catch (error) {
          console.error("Error fetching signature:", error);
          return null; // Return null on failure
        }
      }),
    );
    setStoredSignatures(res);
  }, [allSignatures]);

  const initAtachments = useCallback(async () => {
    const res = await Promise.all(
      allSignatures.map(async (item) => {
        try {
          const { data } = await axios.get(item.url);
          return {
            ...data,
            customData: {
              ...data?.customData,
              savedSignatureId: item.id,
              isSignatureDefault: item.isDefault,
            },
          };
        } catch (error) {
          console.error("Error fetching signature:", error);
          return null; // Return null on failure
        }
      }),
    );
    let attachmentsArray = [];
    if (res && res?.length > 0) {
      attachmentsArray =
        res
          ?.filter((el) => el?.customData)
          .map((el) => {
            return {
              ...el?.customData?.atachment,
              isDefault: el?.customData?.isSignatureDefault,
            };
          }) || [];
    }
    // return attachmentsArray;

    setStoredAttachments(attachmentsArray);
  }, [allSignatures]);

  // const initTotalWidgets = useCallback(async () => {
  //   if (stateInstance) {
  //     const totalWidgetsCount = await getTotalWidgetsCount({
  //       instance: stateInstance,
  //       PSPDFKit: statePSPDFKit,
  //     });

  //     setTotalWidgets(totalWidgetsCount);
  //   }
  // }, [getTotalWidgetsCount, stateInstance, statePSPDFKit]);

  const initIsSignAllDisabled = useCallback(async () => {
    if (stateInstance) {
      const res = await getIsSignAllDisabled({
        instance: stateInstance,
        PSPDFKit: statePSPDFKit,
      });

      stateInstance.setViewState((state) => state.set("zoom", zoomIndex));

      setIsSignAllDisabled(res);
    }
  }, [getIsSignAllDisabled, stateInstance, statePSPDFKit, zoomIndex]);

  const checkIfFilledWithSignatures = useCallback(async () => {
    if (stateInstance && statePSPDFKit) {
      const totalPages = stateInstance?.totalPageCount;

      let widgetsSignaturesToSignCount = 0;
      let widgetsInitialsToSignCount = 0;
      let widgetsDateCount = 0;
      let widgetsCheckboxCount = 0;
      let widgetsTextCount = 0;

      let signaturesCount = 0;
      let initialsCount = 0;

      const pageIndexes = Array.from(Array(totalPages).keys());
      const allFormFieldValues = stateInstance.getFormFieldValues();

      try {
        if (pageIndexes.length > 0 && statePSPDFKit && stateInstance) {
          await Promise.all(
            pageIndexes.map(async (page) => {
              try {
                const allAnnotations =
                  stateInstance.getAnnotations &&
                  (await stateInstance.getAnnotations(page));

                const widgetsSignaturesToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_WIDGET"),
                );
                const widgetsInitialsToSign = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("SIGNATURE_INITIALS"),
                );
                const widgetsDate = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("DATE_WIDGET"),
                );
                const widgetsCheckbox = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("CHECKBOX_WIDGET"),
                );
                const widgetsText = allAnnotations.filter(
                  (el: Annotation) =>
                    el.formFieldName?.startsWith("TEXT_WIDGET"),
                );

                const signatures = allAnnotations.filter((el: Annotation) => {
                  return el.isSignature && !el.customData?.isInitial;
                });
                const initials = allAnnotations.filter(
                  (el: Annotation) =>
                    el.isSignature && el.customData?.isInitial,
                );

                // Increment counts
                widgetsSignaturesToSignCount += widgetsSignaturesToSign.size;
                widgetsInitialsToSignCount += widgetsInitialsToSign.size;
                widgetsDateCount += widgetsDate.size;
                widgetsCheckboxCount += widgetsCheckbox.size;
                widgetsTextCount += widgetsText.size;

                signaturesCount += signatures.size;
                initialsCount += initials.size;
              } catch (error) {
                console.log("error:", error);
              }
            }),
          );
        }
      } catch (error) {
        console.log("error:", error);
      }

      await sleep(1000);

      const savedJSON = getSavedJSON();
      const checkboxesCount =
        allFormFieldValues && savedJSON?.formFieldValues
          ? savedJSON.formFieldValues
              ?.filter((el: any) => el.name?.startsWith("CHECKBOX_WIDGET"))
              .filter((el: any) => el.value && el.value[0] !== "Off")?.length
          : 0;
      // const checkboxesCount = allFormFieldValues
      //   ? Object?.entries(allFormFieldValues)
      //       ?.filter((el: any) => el[0].startsWith("CHECKBOX_WIDGET"))
      //       .filter((el) => el[1] && el[1][0] !== "Off")?.length
      //   : 0;
      const datesCount = allFormFieldValues
        ? Object?.entries(allFormFieldValues)
            ?.filter((el: any) => el[0].startsWith("DATE_WIDGET"))
            .filter((el) => el[1] && el[1][0] !== "")?.length
        : 0;
      const allTextWidgets = allFormFieldValues
        ? Object?.entries(allFormFieldValues)?.filter((el: any) =>
            el[0].startsWith("TEXT_WIDGET"),
          )
        : 0;
      const textCount = allTextWidgets
        ? allTextWidgets?.filter((el: any) => el[1] && el[1][0] !== "")?.length
        : 0;

      setTotalSigned(
        signaturesCount +
          initialsCount +
          checkboxesCount +
          datesCount +
          textCount,
      );
      setTotalWidgets(
        widgetsSignaturesToSignCount +
          widgetsInitialsToSignCount +
          widgetsDateCount +
          widgetsCheckboxCount +
          widgetsTextCount,
      );
    }
  }, [stateInstance, statePSPDFKit, getSavedJSON]);

  useEffect(() => {
    checkIfFilledWithSignatures();
  }, [checkIfFilledWithSignatures, stateChanged]);

  const handleSignAll = async () => {
    if (stateInstance) {
      await Promise.all([
        signAllSignatureFields({
          PSPDFKit: statePSPDFKit,
          instance: stateInstance,
        }),
        signAllFormFields({
          PSPDFKit: statePSPDFKit,
          instance: stateInstance,
        }),
      ]);
    }
  };

  // useEffect(() => {
  //   initTotalWidgets();
  // }, [initTotalWidgets, isFooter]);

  // useEffect(() => {
  //   initTotalSigned();
  // }, [initTotalSigned, isFooter]);

  useEffect(() => {
    initSignatures();
  }, [initSignatures]);

  useEffect(() => {
    initAtachments();
  }, [initAtachments]);

  useEffect(() => {
    initIsSignAllDisabled();
  }, [initIsSignAllDisabled, stateChanged]);

  useEffect(() => {
    const savedJSON = getSavedJSON();
    if (!savedJSON) {
      const savedEditedJSON = docToSign?.instantJson
        ? {
            ...docToSign?.instantJson,
            ...(docToSign?.instantJson.annotations?.length > 0 && {
              annotations: docToSign?.instantJson?.annotations.map(
                (item: any) => ({
                  ...item,
                }),
              ),
            }),
          }
        : null;

      const dataStringified =
        savedEditedJSON && JSON.stringify(savedEditedJSON);
      localStorage.setItem("instantJSON", dataStringified);
    }
  }, [docToSign?.instantJson, getSavedJSON, inboxDocId, linkId]);

  useEffect(() => {
    if (docToSign?.file?.url) {
      const container = containerRef.current;

      let PSPDFKit: any;
      let instance: Instance;

      (async function createContainer() {
        PSPDFKit = await import("pspdfkit");
        // setPSPDFKit(PSPDFKit);

        if (container) {
          const savedJSON = getSavedJSON();
          // const storedSignatures = await getStoredSignatures(
          //   isCreateInitial?.current,
          // );
          // const storedAttachments = await getStoredAtachments();
          const filteredStoredSignatures = storedSignatures?.filter(
            (el: any) => el,
          );

          const initialViewState = new PSPDFKit.ViewState({
            scrollMode: PSPDFKit.ScrollMode.CONTINUOUS,
            layoutMode: PSPDFKit.LayoutMode.SINGLE,
            pageSpacing: 10,
            spreadSpacing: 50,
          });

          const emptyButton = {
            type: "custom",
            id: "emptyButton",
            node: getEmptyNode(5, `1px solid ${palette.grey30}`),
          };

          // const signAllButton = {
          //   type: "custom",
          //   id: "emptyButton",
          //   node: getButtonNode("Sign all boxes", signIcon, "#F1F3F9"),
          //   onPress: async () => {
          //     if (instance && PSPDFKit) {
          //       await signAllSignatureFields({
          //         PSPDFKit,
          //         instance,
          //       });
          //     }
          //     await signAllFormFields({
          //       PSPDFKit,
          //       instance,
          //     });
          //   },
          //   disabled: isSignAllDisabled,
          // };

          // const clearAllButton = {
          //   type: "custom",
          //   id: "emptyButton",
          //   node: getButtonNode("Clear all boxes", eraseIcon, "#F1F3F9"),
          //   onPress: async () => {
          //     if (instance) {
          //       await cleanAllSignatureFields({ instance, PSPDFKit });
          //     }
          //   },
          //   disabled: totalSigned === 0,
          // };

          // const signedStatus = {
          //   type: "custom",
          //   id: "emptyButton",
          //   node: signedStatusNode(totalSigned, totalWidgets),
          // };

          const moveButton = {
            type: "custom",
            id: "moveToFolder",
            title: "Move to another folder",
            icon: moveFolderIcon,
            onPress: async () => {
              inboxDocId && handleMoveInboxDocument();
              sentDocId && handleMoveSentDocument();
            },
          };

          const exportButton = {
            type: "custom",
            id: "export",
            icon: downloadIcon,
            onPress: async () => {
              let res;
              if (docToSign) {
                if (linkId) {
                  res = await SharedDocuments.downloadGuestRecipientDoc({
                    id: linkId,
                  });
                } else if (ownerLinkId) {
                  res = await SharedDocuments.downloadGuestOwnerDoc({
                    id: ownerLinkId,
                  });
                } else if (sentDocId) {
                  res = await SharedDocuments.downloadSentDoc({
                    id: sentDocId,
                  });
                } else {
                  res = await SharedDocuments.downloadInboxDoc({
                    id: docToSign.id,
                  });
                }
              }
              if (res?.file?.url && docToSign) {
                axios
                  .get(res?.file?.url, {
                    responseType: "blob",
                  })
                  .then(async (res) => {
                    if (user?.isPremium && !user?.isWebPremium) {
                      fileDownload(res.data, `${docToSign?.name}.pdf`);
                    } else {
                      const newFileData = await toArrayBuffer(res.data);
                      const updatedBuffer =
                        newFileData &&
                        (await handleAddWaterMark(newFileData as ArrayBuffer));
                      const blob =
                        updatedBuffer &&
                        new Blob([updatedBuffer], {
                          type: "application/pdf",
                        });
                      blob && fileDownload(blob, `${docToSign?.name}.pdf`);
                    }
                  });
              }
            },
          };

          const printButton = {
            type: "custom",
            id: "print",
            icon: printIcon,
            onPress: async () => {
              instance.print({ excludeAnnotations: true });
            },
          };

          const toolbarItems = [
            { type: "sidebar-thumbnails" },
            { type: "pager" },
            { type: "zoom-out" },
            { type: "zoom-in" },
            { type: "zoom-mode" },
            // isSigningStarted && !isMobile && signAllButton,
            // isSigningStarted && !isMobile && clearAllButton,
            // isSigningStarted && !isMobile && signedStatus,
            { type: "spacer" },
            { type: "search" },
            exportButton,
            printButton,
          ].filter(Boolean);

          const list =
            filteredStoredSignatures &&
            filteredStoredSignatures?.length > 0 &&
            filteredStoredSignatures[0].type
              ? PSPDFKit.Immutable?.List(
                  filteredStoredSignatures
                    // ?.filter((el) =>
                    //   !isCreateInitial.current
                    //     ? !el?.customData?.isInitial
                    //     : true,
                    // )
                    ?.map(PSPDFKit.Annotations.fromSerializableObject),
                )
              : [];
          // const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);
          // const attachmentsArray =
          //   attachmentsString && JSON.parse(attachmentsString);
          // const attachmentsArray =
          //   storedSignatures
          //     ?.filter((el) => el.customData?.atachment)
          //     .map((el) => el.customData?.atachment) || [];

          docToSign.status === "COMPLETED" &&
            (inboxDoc || sentDocId) &&
            toolbarItems.push(moveButton);
          !isMobile && toolbarItems.push(emptyButton);

          PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.
          setStateInstance(null);
          setStatePSPDFKit(null);

          const activateFields = () => {
            if (isSigningStarted) {
              const innerDoc =
                instance?.contentDocument || instance?.contentWindow?.document;
              const readOnlyInputs = innerDoc?.querySelectorAll("input");
              const readOnlyText = innerDoc?.querySelectorAll<HTMLElement>(
                ".PSPDFKit-Annotation-Widget-Text",
              );
              const readOnlyCheckboxes =
                innerDoc?.querySelectorAll<HTMLElement>(
                  ".PSPDFKit-Annotation-Widget-CheckBox",
                );
              const readOnlySignatures =
                innerDoc?.querySelectorAll<HTMLElement>(
                  ".PSPDFKit-Annotation-Widget-Signature",
                );
              // const signatureFigures =
              //   innerDoc?.querySelectorAll<HTMLElement>("figure");

              // signatureFigures?.forEach((item) => {
              //   item.style.pointerEvents = "auto";
              //   return "";
              // });

              readOnlyInputs?.forEach((item) =>
                item.removeAttribute("disabled"),
              );

              [
                ...readOnlyText,
                ...readOnlyCheckboxes,
                ...readOnlySignatures,
              ]?.forEach((item) => {
                item.style.pointerEvents = "auto";
                return "";
              });
            }
          };

          const {
            UI: { createBlock, Recipes, Interfaces },
          } = PSPDFKit;

          const configuration = {
            container,
            document: docToSign.file.url,
            baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
            initialViewState,
            toolbarItems,
            enableClipboardActions: true,
            enableHistory: true,
            locale: "en",
            styleSheets: ["/custom-pspdfkit.css"],
            licenseKey: isLocal ? "" : process.env.REACT_APP_PSPDFKIT_ID,
            ...(savedJSON && {
              instantJSON: savedJSON,
            }),
            customRenderers: {
              Annotation: ({ annotation }: any) => {
                const placeholderEl = document.createElement("div");
                if (annotation.formFieldName?.startsWith("SIGNATURE")) {
                  placeholderEl.style.fontSize = "24px";
                  placeholderEl.style.height = "100%";
                  placeholderEl.style.display = "flex";
                  placeholderEl.style.justifyContent = "center";
                  placeholderEl.style.alignItems = "center";
                  placeholderEl.innerHTML = annotation.customData?.isInitial
                    ? "Initials"
                    : "Sign";
                }
                const parentDiv = document.createElement("div");
                parentDiv.style.height = "100%";
                parentDiv.appendChild(placeholderEl);
                return {
                  node: parentDiv,
                  append: true,
                };
              },
            },
            ui: {
              [Interfaces.CreateSignature]: ({ props }: any) => {
                return {
                  content: createBlock(
                    Recipes.CreateSignature,
                    props,
                    ({ ui }: any) => {
                      if (isCreateInitial.current) {
                        ui.getBlockById("title").children = "Create Initial";
                        ui.getBlockById(
                          "save-signature-checkbox",
                        )._props.label = "Save Initial";

                        const textInput = ui.getBlockById(
                          "signature-text-input",
                        );
                        textInput._props.placeholder = "Initial";
                        textInput._props.label = "Initial here";
                        textInput._props.clearLabel = "Clear initial";

                        const freehand = ui.getBlockById("freehand-canvas");
                        freehand._props.placeholder = "Initial here";
                        freehand._props.clearLabel = "Clear initial";

                        const fontselect = ui.getBlockById("font-selector");
                        if (fontselect._props.items[0].label === "Signature") {
                          fontselect._props.items = fontselect._props.items.map(
                            (item: any) => {
                              return { id: item.id, label: "Initial" };
                            },
                          );
                        }
                      }
                      return ui.createComponent();
                    },
                  ).createComponent(),
                };
              },
              [Interfaces.SignaturesList]: ({ props }: any) => {
                return {
                  content: createBlock(
                    Recipes.SignaturesList,
                    props,
                    ({ ui }: any) => {
                      if (isCreateInitial.current) {
                        ui.getBlockById("title").children = "Initials";
                        ui.getBlockById("add-signature")._props.label =
                          "Add initial";
                      }
                      return ui.createComponent();
                    },
                  ).createComponent(),
                };
              },
            },
            renderPageCallback() {
              activateFields();
            },
          };

          PSPDFKit.preloadWorker(configuration);
          instance = await PSPDFKit.load(configuration);

          setStateInstance(instance);
          setStatePSPDFKit(PSPDFKit);

          instance.setViewState((viewState) => {
            return viewState.set(
              "sidebarMode",
              isMobile ? null : PSPDFKit.SidebarMode.THUMBNAILS,
            );
          });

          instance.addEventListener(
            "document.saveStateChange",
            async (event) => {
              if (event.hasUnsavedChanges) {
                dispatch(setStoredInstance(instance));
              }
              setStateChanged((prevState) => !prevState);
            },
          );

          instance.addEventListener("annotations.didSave", async () => {
            saveInstanceJSON({ instance, recipientId });

            const annotId = sessionStorage.getItem("created_annotation_id");
            const formFields = await instance.getFormFields();

            // Get a signature form with the specific name you want.
            const field =
              annotId &&
              formFields.find((formField) => formField.name.includes(annotId));

            if (field) {
              const res = await instance.getOverlappingAnnotations(field);
              const newAnnotation = res.get(0);

              newAnnotation?.id &&
                sessionStorage.setItem("annotation_id", newAnnotation.id);
              sessionStorage.removeItem("created_annotation_id");
            }

            // await sleep(1000);
            // setAnnotationsChanged((prevState) => !prevState);
            // isAnnotationsChanged.current = !isAnnotationsChanged.current;
          });

          instance.addEventListener("formFieldValues.didSave", async () => {
            saveInstanceJSON({ instance, recipientId });
            // setAnnotationsChanged((prevState) => !prevState);
            // isAnnotationsChanged.current = !isAnnotationsChanged.current;
          });

          instance.addEventListener("annotations.press", async (event) => {
            const { annotation } = event;
            if (!isSigningStarted) {
              return;
            }
            isCreateInitial.current = !!annotation?.customData?.isInitial;
            sessionStorage.removeItem("toSaveSignature");

            if (isCreateInitial.current && annotation) {
              sessionStorage.setItem("created_annotation_id", annotation.id);
            }

            if (annotation.formFieldName?.startsWith("SIGNATURE_INITIALS")) {
              isCreateInitial.current = true;
            }
            const filteredStoredSignatures = storedSignatures?.filter(
              (el: any) => el,
            );
            const filteredList =
              filteredStoredSignatures &&
              PSPDFKit.Immutable.List(
                filteredStoredSignatures
                  ?.filter((el) =>
                    isCreateInitial.current
                      ? el?.customData?.isInitial
                      : !el?.customData?.isInitial,
                  )
                  ?.map(PSPDFKit.Annotations.fromSerializableObject),
              );

            filteredList && instance.setStoredSignatures(filteredList);
            const instSignatures = await instance
              .getStoredSignatures()
              .then((signatures) =>
                signatures
                  .map(PSPDFKit.Annotations.toSerializableObject)
                  .toJS(),
              );
            const isDefaultSignature = instSignatures.some(
              (el) =>
                el?.customData?.isSignatureDefault &&
                (isCreateInitial.current
                  ? el?.customData?.isInitial
                  : !el?.customData?.isInitial),
            );
            event.preventDefault &&
              annotation.formFieldName?.startsWith("SIGNATURE") &&
              instSignatures?.length > 0 &&
              event.preventDefault();

            activateFields();
            if (
              instSignatures?.length > 0 &&
              !annotation.isSignature &&
              annotation.formFieldName?.startsWith("SIGNATURE")
            ) {
              signSignatureField({
                annotation,
                instance,
                PSPDFKit,
                isInitial: isCreateInitial.current,
              });
            }

            if (
              instSignatures?.length > 0 &&
              isDefaultSignature &&
              annotation.formFieldName?.startsWith("SIGNATURE")
            ) {
              instance.setViewState((viewState) =>
                viewState.set("interactionMode", null),
              );
            }
          });

          const filteredAttachments = storedAttachments?.filter((el) => el.id);

          if (filteredAttachments?.length > 0 && filteredAttachments[0]?.url) {
            const blobs = await Promise.all(
              filteredAttachments.map(({ url }: any) =>
                fetch(url).then((res) => res.blob()),
              ),
            );
            blobs.forEach(instance.createAttachment);
          }

          list?.size && instance.setStoredSignatures(list);

          instance.addEventListener(
            "storedSignatures.create",
            async (annotation: Annotation) => {
              isToDeleteSignature.current = true;
              if (annotation instanceof PSPDFKit.Annotations.InkAnnotation) {
                await instance.create(annotation);
                // saveInkAnnotationAtachment({ annotation, instance });
              }
              // if (annotation.imageAttachmentId) {
              //   await saveImageAnnotationAtachment({ annotation, instance });
              //   sessionStorage.setItem("toDeleteSignature", "true");
              // }
              await saveSignatures({
                annotation,
                instance,
                PSPDFKit,
                isInitial: isCreateInitial.current,
              });
              await cleanChangedFields({
                instance,
                isInitial: isCreateInitial.current,
              });
              await sleep(500);
              setStoredSignaturesInitialized(false);
            },
          );

          instance.addEventListener(
            "storedSignatures.delete",
            async (annotation: any) => {
              await deleteSignature({
                annotation,
                instance,
                PSPDFKit,
                userId: user?.id,
                isInitial: isCreateInitial.current,
              });
              await cleanChangedFields({
                instance,
                isInitial: isCreateInitial.current,
              });
              await sleep(500);
              setStoredSignaturesInitialized(false);
            },
          );

          instance.addEventListener(
            "annotations.create",
            async (createdAnnotations) => {
              const annotation = createdAnnotations.get(0);
              const toSaveSignature = sessionStorage.getItem("toSaveSignature");

              if (
                annotation &&
                annotation instanceof PSPDFKit.Annotations.InkAnnotation &&
                toSaveSignature !== "false"
              ) {
                saveInkAnnotationAtachment({
                  annotation,
                  instance,
                  isInitial: isCreateInitial.current,
                });
              }
              if (
                annotation &&
                annotation instanceof PSPDFKit.Annotations.ImageAnnotation
              ) {
                await sleep(500);
              }
              if (isToDeleteSignature.current && annotation) {
                await instance.delete(annotation.id);
                isToDeleteSignature.current = false;
              }
              if (annotation?.customData?.savedSignatureId && instance) {
                const dataToSave = {
                  id: annotation.customData.savedSignatureId as string,
                  data: {
                    isDefault: true,
                  },
                };

                linkId
                  ? await Signatures.putGuestSignature({
                      docId: linkId,
                      data: dataToSave,
                    })
                  : await Signatures.putSignature(dataToSave);

                await cleanAllSignatureFields({ instance, PSPDFKit });
                dispatch(setIsUpdated(true));
              }
              // if (annotation && toSaveSignature) {
              //   await saveSignatures({ annotation, instance, PSPDFKit });
              //   sessionStorage.removeItem("toSaveSignature");
              // }
            },
          );

          instance.addEventListener(
            "viewState.currentPageIndex.change",
            (page: any) => {
              setOnPageIndex(page);
            },
          );

          instance.addEventListener("viewState.change", () => {
            sessionStorage.removeItem("toSaveSignature");
          });

          return () => PSPDFKit && PSPDFKit.unload(container);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    linkId,
    inboxDocId,
    ownerLinkId,
    sentDocId,
    docToSign?.file?.url,
    activeRecipient,
    isCreateInitial,
    isSigningStarted,
    // annotationsChanged,
    // totalWidgets,
    // totalSigned,
    storedSignaturesInitialized,
    isToDeleteSignature,
    storedSignatures,
    storedAttachments,
    // isSignAllDisabled,
  ]);

  return (
    <>
      <div
        onMouseEnter={() => {
          dispatch(setIsDetailedViewMenu(false));
        }}
        ref={containerRef}
        style={{
          width: "100%",
          height: "100%",
          zIndex: "100",
          position: "fixed",
          left: "0",
          top: "9.9rem",
          backgroundColor: "white",
        }}
      />
      {!isMobile && stateInstance && isSigningStarted && (
        <CounterInfo
          totalSigned={totalSigned}
          totalWidgets={totalWidgets}
          handleSignAll={handleSignAll}
          handleCleanAll={() =>
            stateInstance &&
            cleanAllSignatureFields({
              instance: stateInstance,
              PSPDFKit: statePSPDFKit,
            })
          }
          isSignAllDisabled={isSignAllDisabled}
        />
      )}
      {!isMobile &&
        docToSign?.status === "REJECTED" &&
        (inboxDocId || sentDocId) && <RejectionWidget />}
      {!isMobile &&
        (docToSign?.status === "NEED_TO_SIGN" ? isSigningStarted : true) && (
          <RecipientsWidget isGuest={!!(linkId || ownerLinkId)} />
        )}
      {!isMobile &&
        stateInstance &&
        docToSign?.status === "NEED_TO_SIGN" &&
        isSigningStarted && (
          <SignatureWidget onChangeSignature={handleChangeSignature} />
        )}
      {docToSign?.status === "NEED_TO_SIGN" && (
        <RecipientSigningFooter
          stateInstance={stateInstance}
          stateChanged={stateChanged}
          statePSPDFKit={statePSPDFKit}
          handleSignAll={handleSignAll}
          handleCleanAll={() =>
            stateInstance &&
            cleanAllSignatureFields({
              instance: stateInstance,
              PSPDFKit: statePSPDFKit,
            })
          }
          totalSigned={totalSigned}
          totalWidgets={totalWidgets}
          isSignAllDisabled={isSignAllDisabled}
          setIsFooter={setIsFooter}
          isFooter={isFooter}
        />
      )}
      {docToSign?.status === "NEED_TO_SIGN" && (
        <GoNextButton
          // fromTop={100}
          stateInstance={stateInstance}
          statePSPDFKit={statePSPDFKit}
          stateChanged={stateChanged}
          setIsSigningStarted={setIsSigningStarted}
          isSigningStarted={isSigningStarted}
        />
      )}
    </>
  );
};
