import React, {
  FC,
  useReducer,
  useMemo,
  useLayoutEffect,
  createContext,
} from "react";

import { setIsMobile, setIsTablet } from "./actions";
import { InitialState, themeReducer, TState } from "./reducers";

type ThemeProviderProps = {
  children?: React.ReactNode;
};

export const ThemeContext = createContext<Partial<TState>>({});

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(themeReducer, InitialState);

  useLayoutEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      dispatch(setIsMobile(innerWidth <= 768));
      dispatch(setIsTablet(innerWidth <= 1024));
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      isMobile: state.isMobile,
      isTablet: state.isTablet,
    }),
    [state],
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

const ThemeContextModule = {
  ThemeProvider,
  Consumer: ThemeContext.Consumer,
};

export default ThemeContextModule;
