import { AxiosError, isAxiosError } from "axios";
import queryString from "query-string";

import { toastError, toastSuccess, authErrors, dashboardErrors } from "utils";
import {
  IHttpRejection,
  IFolder,
  IParent,
  ISearchFolder,
  IMeta,
  IGlobalSearchDoc,
} from "types";

import { $http } from "./instance";

export interface IGetFolder {
  id: string;
  orderBy?: string;
  order?: string;
  filter?: string;
  isFilesFirst?: string;
}

export interface IGetFolderResponse {
  items: IParent[];
  folder: IFolder;
  parentFolder: IFolder;
}
export interface ISearchFolderResponse {
  items: ISearchFolder[];
}

export interface ICreateFolder {
  parentFolderId: string;
  folderName: string;
  mobileId?: string;
  createdAt?: string;
}

export interface IDeleteFolder {
  folderIds: string[];
  deletedAt?: string;
}

export interface ISearchFolderRequset {
  query: string;
  filter?: string;
  isFoldersOnly?: string;
}

export interface IUpdateDocument {
  folderName: string;
  modifiedAt?: string;
}

export interface IMoveDocument {
  parentFolderId: string;
  folderIds: string[];
}
export interface IUndoDocument {
  folderIds: string[];
}
interface IUndoDocumentResponse {
  message: string;
}

interface IGlobalSearchRequest {
  query: string;
}
interface IGlobalSearchResponse {
  meta: IMeta;
  items: IGlobalSearchDoc[];
}

export const Folders = {
  getFolders: async (): Promise<any> => {
    try {
      const res = await $http.get(`/clients/folders`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      }
    }
  },
  getRootFolder: async (): Promise<any> => {
    try {
      const res = await $http.get(`/clients/folders/root`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      }
    }
  },
  getFolder: async ({
    id,
    orderBy,
    order,
    filter,
    isFilesFirst,
  }: IGetFolder): Promise<IGetFolderResponse | undefined> => {
    const query = queryString.stringify(
      { orderBy, order, filter, isFilesFirst },
      { skipEmptyString: true },
    );
    try {
      const res = await $http.get(`/clients/folders/${id}?${query}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getFolderParents: async (id: string): Promise<any> => {
    try {
      const res = await $http.get(`/clients/folders/${id}/parents`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getFolderFiles: async (id: string): Promise<any> => {
    try {
      const res = await $http.get(`/clients/folders/${id}/files`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  createFolder: async (data: ICreateFolder): Promise<any> => {
    try {
      const res = await $http.post(`/clients/folders`, data);
      toastSuccess("Folder was successfully created");
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  deleteFolder: async (data: IDeleteFolder): Promise<any> => {
    try {
      const res = await $http.delete(`/clients/folders`, { data });
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  searchFolder: async ({
    query,
    filter,
    isFoldersOnly = "false",
  }: ISearchFolderRequset): Promise<ISearchFolderResponse | undefined> => {
    const queries = queryString.stringify(
      { query, filter, isFoldersOnly },
      { skipEmptyString: true },
    );
    try {
      const res = await $http.get(`/clients/folders/search?${queries}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  updateDocument: async (id: string, data: IUpdateDocument): Promise<any> => {
    try {
      const res = await $http.put(`/clients/folders/${id}`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  clearDocument: async (id: string): Promise<any> => {
    try {
      const res = await $http.put(`/clients/folders/${id}/clear`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  moveDocument: async (
    data: IMoveDocument,
  ): Promise<IGetFolderResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/folders/move`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  undoDocument: async (
    data: IUndoDocument,
  ): Promise<IUndoDocumentResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/folders/undo`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  globalSearch: async ({
    query,
  }: IGlobalSearchRequest): Promise<IGlobalSearchResponse | undefined> => {
    const queries = queryString.stringify({ query }, { skipEmptyString: true });
    try {
      const res = await $http.get(
        `/clients/search?${queries}&timeZone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
};
