import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const BurgerIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 30 / 30; // width / height
  let height = 30;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 8.75H26.25"
        stroke={color || palette.black}
        strokeLinecap="round"
      />
      <path
        d="M3.75 15H26.25"
        stroke={color || palette.black}
        strokeLinecap="round"
      />
      <path
        d="M3.75 21.25H26.25"
        stroke={color || palette.black}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BurgerIcon;
