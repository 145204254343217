import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, settings } from "store";
import { updatedUserInfo } from "store/settings/thunks";
import { setNameRequiredModal } from "store/subscription";

import { PATHES } from "constants/pathes";
import { Input, Button } from "components/UI";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

export const NameRequiredModal: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(settings);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>(user?.lastName || "");
  const { t } = useTranslation("Modals", { keyPrefix: "NameRequired" });

  useEffect(() => {
    if (user?.firstName) {
      setFirstName(user?.firstName);
    }
  }, [user?.firstName]);

  useEffect(() => {
    if (user?.lastName) {
      setLastName(user?.lastName);
    }
  }, [user?.lastName]);

  const handleClose = () => {
    dispatch(setNameRequiredModal(false));
  };

  const handleSubmit = async () => {
    dispatch(updatedUserInfo({ firstName, lastName }));
    handleClose();
    navigate(PATHES.FILE_RECIPIENTS);
  };

  return (
    <div className={styles.NameRequiredModal}>
      <ModalHeader onClose={handleClose} title={t("title")} info={t("info")} />
      <Input
        type="text"
        placeholder={t("inputFirstNamePlaceholder")}
        name="fisrtName"
        label={t("inputFirstNameLabel")}
        onChange={(e) => setFirstName(e.target.value)}
        onClear={() => setFirstName("")}
        value={firstName}
        className={styles.firstInput}
        isClearButton
        isRequired
      />
      <Input
        type="text"
        placeholder={t("inputLastNamePlaceholder")}
        name="lastName"
        label={t("inputLastNameLabel")}
        onChange={(e) => setLastName(e.target.value)}
        onClear={() => setLastName("")}
        value={lastName}
        isClearButton
        isRequired
      />
      <Button
        variant="primary"
        title={t("submitButtonTitle")}
        onClick={handleSubmit}
        isDisabled={!firstName || !lastName}
      />
    </div>
  );
};
