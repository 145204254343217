import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector, requestSigning } from "store";
import { getDraft } from "store/signings/thunks";
import { setFilesToSave } from "store/requestSigning";

import { RecipientsList, RecipientsFile } from "components";
import { Button, Spinner } from "components/UI";

import { useSigningsRequest } from "hooks";
import { palette, cs } from "utils";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const FileRecipients = () => {
  const { recipients, editedDraft, isUploadInProgress, fileName, filesToSave } =
    useAppSelector(requestSigning);
  const dispatch = useAppDispatch();
  const { handleCreateDraft, handleUpdateDraft } = useSigningsRequest();
  const { t } = useTranslation("Pages", {
    keyPrefix: "FileRecipients",
  });
  const { draftId } = useParams();
  const navigate = useNavigate();

  const isSubmitDisabled =
    recipients.some((item) => !item.name || !item.email || item.error) ||
    !fileName ||
    filesToSave.length === 0;

  const handleSubmit = async () => {
    if (isUploadInProgress) return;
    if (editedDraft) {
      await handleUpdateDraft();
      dispatch(getDraft(editedDraft.id));
      dispatch(setFilesToSave([]));
      navigate(`${PATHES.SIGNINGS_AREA}/${draftId}`);
    } else {
      handleCreateDraft();
    }
  };

  return (
    <div className={styles.FileRecipients}>
      <div className={cs([styles.main, isUploadInProgress && styles.progress])}>
        <RecipientsFile />
        <RecipientsList />
      </div>

      <Button
        variant="primary"
        title={
          isUploadInProgress ? (
            <Spinner color={palette.white} />
          ) : (
            t("nextButtonTitle")
          )
        }
        onClick={handleSubmit}
        isDisabled={isSubmitDisabled}
      />
    </div>
  );
};
