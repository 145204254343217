import { FC, Fragment, SyntheticEvent } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import { setSearch } from "store/dashboard";
import { setCurrentFolder as setCurrentTrashFolder } from "store/trashBin";
import { setCurrentFolder } from "store/folders";

import { Icon } from "components/UI";

import { PATHES } from "constants/pathes";
import { IParent } from "types";
import { palette, cs } from "utils";
import { useActions } from "hooks";

import styles from "./styles.module.scss";

type BreadcrumbsGlobalSearchProps = {
  parents?: IParent[];
  rootFolderName?: string;
  isSent?: boolean;
  isInbox?: boolean;
  isDraft?: boolean;
  isTrash?: boolean;
  isViewHistory?: boolean;
  onChange?: () => void;
};

export const BreadcrumbsGlobalSearchDoc: FC<BreadcrumbsGlobalSearchProps> = ({
  parents,
  rootFolderName,
  isSent,
  isInbox,
  isDraft,
  isTrash,
  isViewHistory,
  onChange = () => {},
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Pages", {
    keyPrefix: "Signings",
  });
  const { t: tG } = useTranslation("General");
  const curPar = parents?.slice().reverse() || [];
  const { handleShowViewHistory } = useActions();

  const handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(setSearch(""));
  };

  const handleOpenFolder = (e: SyntheticEvent, id: string) => {
    e.stopPropagation();
    dispatch(setSearch(""));
    dispatch(isTrash ? setCurrentTrashFolder(id) : setCurrentFolder(id));
  };

  const getSigningPath = () => {
    if (isSent) {
      return (
        <NavLink
          to={PATHES.SENT}
          className={styles.link}
          onClick={handleStopPropagation}
        >
          {rootFolderName} / {t("sent")}
        </NavLink>
      );
    }
    if (isInbox) {
      return (
        <NavLink
          to={PATHES.INBOX}
          className={styles.link}
          onClick={handleStopPropagation}
        >
          {rootFolderName} / {t("inbox")}
        </NavLink>
      );
    }
    if (isDraft) {
      return (
        <NavLink
          to={PATHES.DRAFT}
          className={styles.link}
          onClick={handleStopPropagation}
        >
          {rootFolderName} / {t("draft")}
        </NavLink>
      );
    }
  };
  const signigsPath = getSigningPath();

  const handleClickHistory = () => {
    onChange();
    handleShowViewHistory();
  };

  return (
    <div className={styles.BreadcrumbsGlobalSearchDoc}>
      {isViewHistory ? (
        <div
          className={styles.viewActionHistory}
          onClick={(e) => e.stopPropagation()}
        >
          <div>{tG("deleted")},</div>
          <Icon name="info" color={palette.red} size={14} />
          <div className={styles.button} onClick={handleClickHistory}>
            {" "}
            {tG("viewActionHistory")}
          </div>
        </div>
      ) : (
        <>
          {signigsPath && signigsPath}
          {curPar.length > 3 ? (
            <>
              <NavLink
                to={isTrash ? PATHES.TRASH : PATHES.HOME}
                className={cs([styles.link, styles.short])}
                onClick={(e) =>
                  handleOpenFolder(e, curPar[curPar.length - 4].id)
                }
              >
                ...
              </NavLink>
              <NavLink
                to={isTrash ? PATHES.TRASH : PATHES.HOME}
                className={styles.link}
                onClick={(e) =>
                  handleOpenFolder(e, curPar[curPar.length - 3].id)
                }
              >
                / {curPar[curPar.length - 3].name}
              </NavLink>
              <NavLink
                to={isTrash ? PATHES.TRASH : PATHES.HOME}
                className={styles.link}
                onClick={(e) =>
                  handleOpenFolder(e, curPar[curPar.length - 2].id)
                }
              >
                / {curPar[curPar.length - 2].name}
              </NavLink>
              <NavLink
                to={isTrash ? PATHES.TRASH : PATHES.HOME}
                className={styles.link}
                onClick={(e) =>
                  handleOpenFolder(e, curPar[curPar.length - 1].id)
                }
              >
                / {curPar[curPar.length - 1].name}
              </NavLink>
            </>
          ) : (
            curPar.map((el, index) => {
              const rootName =
                rootFolderName && index === 0 ? rootFolderName : el.name;
              return (
                <Fragment key={index}>
                  <NavLink
                    to={isTrash ? PATHES.TRASH : PATHES.HOME}
                    className={styles.link}
                    onClick={(e) => handleOpenFolder(e, el.id)}
                  >
                    {rootName}
                  </NavLink>
                  {index < curPar.length - 1 ? "/" : ""}
                </Fragment>
              );
            })
          )}
        </>
      )}
    </div>
  );
};
