import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, signings, folders } from "store";
import {
  setIsDeleteSentItemsModal,
  setIsSentCancelRequestModal,
  setIsMovingItemsModal,
} from "store/signings";
import {
  setDestinationFolder,
  setCurrentContextFolder,
  setRenameModalItem,
} from "store/folders";
import { cancelRequest } from "store/signings/thunks";

import {
  AreYouSureModal,
  MovingItemsModal,
  CreateFolderModal,
  RenameModal,
} from "components";
import { Modal } from "components/UI";
import { toastSuccess } from "utils";
import { useActions } from "hooks";

import styles from "./styles.module.scss";

type SigningsSentTableModalsProps = {
  refreshState: () => void;
};

export const SigningsSentTableModals: FC<SigningsSentTableModalsProps> = ({
  refreshState,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "SigningsSent.Modals",
  });
  const { handleDeleteSentSuccess } = useActions();
  const {
    isDeleteSentItemsModal,
    isSentCancelRequestModal,
    isMovingItemsModal,
    selectedSentItems,
    sentToCancelRequest,
    sentDocs,
  } = useAppSelector(signings);
  const { renameModalItem } = useAppSelector(folders);
  const isSomeInProgress = selectedSentItems.some(
    (el) => el.status === "IN_PROGRESS",
  );
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);

  const handleCloseAreYouSureMoveToBin = () => {
    dispatch(setIsDeleteSentItemsModal(false));
  };

  const handleConfirmAreYouSureMoveToBin = async () => {
    await handleDeleteSentSuccess(refreshState);
    handleCloseAreYouSureMoveToBin();
  };

  const handleCloseAreYouSureConfirmRequest = () => {
    dispatch(setIsSentCancelRequestModal(false));
  };

  const handleConfirmAreYouSureConfirmRequest = async () => {
    await Promise.all(
      sentToCancelRequest.map((el) => dispatch(cancelRequest(el))),
    );
    handleCloseAreYouSureConfirmRequest();
    refreshState();
    toastSuccess(t("toastCancelRequestSuccess"));
  };

  const handleCloseMoveModal = () => {
    dispatch(setIsMovingItemsModal(false));
    dispatch(setDestinationFolder(null));
    dispatch(setCurrentContextFolder(null));
  };

  const handleOpenCreate = () => {
    setIsCreateModal(true);
  };

  const handleCloseCreate = () => {
    setIsCreateModal(false);
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseRenameModal = () => {
    dispatch(setRenameModalItem(""));
    dispatch(setCurrentContextFolder(null));
  };

  return (
    <>
      <Modal
        isShowed={isDeleteSentItemsModal}
        onClose={handleCloseAreYouSureMoveToBin}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureMoveToBin}
          onSubmit={handleConfirmAreYouSureMoveToBin}
          info={t("areYouSureMoveToBin")}
          addInfo={
            isSomeInProgress ? (
              <p className={styles.addInfo}>
                {t("areYouSureMoveToBinInProgress")}
              </p>
            ) : (
              <></>
            )
          }
          confirmButtonTitle={t("areYouSureMoveToBinConfirmButtonTitle")}
        />
      </Modal>
      <Modal
        isShowed={isSentCancelRequestModal}
        onClose={handleCloseAreYouSureConfirmRequest}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureConfirmRequest}
          onSubmit={handleConfirmAreYouSureConfirmRequest}
          info={t("areYouSureCancelRequestInfo")}
          confirmButtonTitle={t("areYouSureCancelRequestConfirmButtonTitle")}
          cancelButtonTitle={t("areYouSureCancelRequestCancelButtonTitle")}
        />
      </Modal>
      <Modal isShowed={isMovingItemsModal}>
        <MovingItemsModal
          onClose={handleCloseMoveModal}
          onCreate={handleOpenCreate}
          refreshState={refreshState}
          isSent
        />
      </Modal>
      <Modal
        styleWrap={{ zIndex: 110 }}
        isShowed={isCreateModal}
        onClose={handleCloseCreate}
      >
        <CreateFolderModal onClose={handleCloseCreate} isMoving />
      </Modal>
      <Modal isShowed={!!renameModalItem}>
        <RenameModal
          onClose={handleCloseRenameModal}
          sentOldName={sentDocs.find((el) => el.id === renameModalItem)?.name}
          refreshState={refreshState}
        />
      </Modal>
    </>
  );
};
