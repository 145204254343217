/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function */
import React from "react";

import { TAction } from "./actions";

export type TState = {
  isMobile: boolean;
  isTablet: boolean;
};

export const InitialState: TState = {
  isMobile: typeof window !== "undefined" && window.innerWidth <= 768,
  isTablet: typeof window !== "undefined" && window.innerWidth <= 1024,
};

export const themeReducer = (state: TState, action: TAction) => {
  const { type, payload } = action;

  switch (type) {
    case "SET_IS_MOBILE":
      return {
        ...state,
        isMobile: payload,
      };

    case "SET_IS_TABLET":
      return {
        ...state,
        isTablet: payload,
      };

    default:
      return state;
  }
};
