import { toast } from "react-toastify";

import { Button } from "components/UI";

import successIcon from "assets/img/toaster/success.svg";
import errorIcon from "assets/img/toaster/error.svg";
import warningIcon from "assets/img/toaster/warning.svg";
import infoIcon from "assets/img/toaster/info.svg";

import styles from "./styles.module.scss";

type UndoProps = {
  onUndo: () => void;
  id: string;
  text: string;
  textUndo: string;
};

type ToastUndoProps = {
  onUndo: () => void;
  onSubmit?: () => void;
  id: string;
  text: string;
  textUndo: string;
};

const Undo = ({ onUndo, text, textUndo, id }: UndoProps) => {
  const handleClick = () => {
    onUndo();
    toast.dismiss(id);
    toastInfo(textUndo);
  };

  return (
    <>
      <div className={styles.Undo}>
        {text}
        <Button
          size="sm"
          variant="textBlack"
          onClick={handleClick}
          title="Undo"
        />
      </div>
    </>
  );
};

export const toastSuccess = (text: string) => {
  toast.success(<div className={styles.UnlimitedText}>{text}</div>, {
    icon: () => <img src={successIcon} alt="success" />,
    toastId: text,
    containerId: "Other",
  });
};

export const toastError = (text: string) => {
  toast.error(<div className={styles.UnlimitedText}>{text}</div>, {
    icon: () => <img src={errorIcon} alt="error" />,
    toastId: text,
    containerId: "Other",
  });
};

export const toastErrorTwoRows = (text: string, additional: string) => {
  toast.error(
    <div className={styles.CustomError}>
      <div className={styles.title}>{text}</div>
      <div className={styles.additional}>{additional}</div>
    </div>,
    {
      icon: () => <img src={errorIcon} alt="error" width={32} height={32} />,
      toastId: text,
      style: { width: "40rem" },
      containerId: "Other",
    },
  );
};

export const toastWarning = (text: string) => {
  toast.warning(text, {
    icon: () => <img src={warningIcon} alt="warning" />,
    toastId: text,
    containerId: "Other",
  });
};

export const toastInfo = (text: string) => {
  toast.info(<div className={styles.UnlimitedText}>{text}</div>, {
    icon: () => <img src={infoIcon} alt="info" />,
    toastId: text,
    containerId: "Other",
  });
};
export const toastUndo = ({
  text,
  textUndo,
  onUndo,
  // onSubmit,
  id,
}: ToastUndoProps) => {
  const handleClose = () => {
    // onSubmit();
    toast.dismiss(text);
  };

  return toast.success(
    <Undo onUndo={onUndo} text={text} textUndo={textUndo} id={id} />,
    {
      toastId: id,
      onClose: handleClose,
      // autoClose: 50000,
      closeOnClick: false,
      icon: () => <img src={successIcon} alt="success" />,
      containerId: "Undo",
    },
  );
};
