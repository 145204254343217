import { AxiosError, isAxiosError } from "axios";

import { toastError, authErrors } from "utils";
import { IHttpRejection, ISignature, TSignatureType } from "types";

import { $http } from "./instance";

interface IGetSignaturesResponse {
  items: ISignature[];
}
export interface ICreateSignatureRequest {
  type?: TSignatureType;
  isDefault?: boolean;
  file: {
    fileSize: number;
  };
}
export interface ICreateGuestSignatureRequest {
  docId: string;
  data: {
    type?: TSignatureType;
    isDefault?: boolean;
    file: {
      fileSize: number;
    };
  };
}
interface ICreateSignatureResponse {
  signature: ISignature[];
  fileUpload: {
    url: string;
    urlExpiresAt: string;
  };
}

export interface IEditSignatureRequest {
  id: string;
  data: {
    isDefault: boolean;
  };
}

export interface IEditGuestSignatureRequest {
  docId: string;
  data: {
    id: string;
    data: {
      isDefault: boolean;
    };
  };
}

export interface IDeleteGuestSignatureRequest {
  docId: string;
  id: string;
}
interface IEditSignatureResponse {
  signature: ISignature[];
  fileUpload: {
    url: string;
    urlExpiresAt: string;
  };
}

export const Signatures = {
  getSignatures: async (): Promise<IGetSignaturesResponse | undefined> => {
    try {
      const res = await $http.get(`/clients/signatures`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  createSignature: async (
    data: ICreateSignatureRequest,
  ): Promise<ICreateSignatureResponse | undefined> => {
    try {
      const res = await $http.post(`/clients/signatures`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  putSignature: async (
    data: IEditSignatureRequest,
  ): Promise<IEditSignatureResponse | undefined> => {
    try {
      const res = await $http.put(`/clients/signatures/${data.id}`, data.data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  deleteSignature: async (
    id: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.delete(`/clients/signatures/${id}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getGuestSignatures: async (
    docId: string,
  ): Promise<IGetSignaturesResponse | undefined> => {
    try {
      const res = await $http.get(
        `/guests/shared-documents/${docId}/signatures`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  createGuestSignature: async ({
    docId,
    data,
  }: ICreateGuestSignatureRequest): Promise<
    ICreateSignatureResponse | undefined
  > => {
    try {
      const res = await $http.post(
        `/guests/shared-documents/${docId}/signatures`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  putGuestSignature: async ({
    docId,
    data,
  }: IEditGuestSignatureRequest): Promise<
    IEditGuestSignatureRequest | undefined
  > => {
    try {
      const res = await $http.put(
        `/guests/shared-documents/${docId}/signatures/${data.id}`,
        data.data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  deleteGuestSignature: async ({
    id,
    docId,
  }: IDeleteGuestSignatureRequest): Promise<
    { message: string } | undefined
  > => {
    try {
      const res = await $http.delete(
        `/guests/shared-documents/${docId}/signatures/${id}`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        console.error(e);
      }
    }
  },
};
