import { FC } from "react";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch, signings, dashboard } from "store";
import { setSentDoc } from "store/signings";

import { ContextMenu, RowActions, DocImage, DocIDCopy } from "components";
import { Checkbox } from "components/UI";

import { cs, getSigningsItems } from "utils";
import {
  ISentDoc,
  SIGNING_STATUSES,
  SigningsType,
  TSharedDocumentStatus,
} from "types";
import { useTableRow } from "hooks";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

interface ISigningsTableRowProps {
  onChange: () => void;
  item: ISentDoc;
  signingsType: SigningsType;
  signingsStatus?: TSharedDocumentStatus;
}

export const SigningsSentTableRow: FC<ISigningsTableRowProps> = ({
  onChange,
  item,
  signingsType,
  signingsStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { status, id, name, sentAt, previewFile, inProgressState } = item;
  const { selectedSentItems } = useAppSelector(signings);
  const { dateFormat, timeFormat } = useAppSelector(dashboard);
  const {
    isClicked,
    isHovered,
    isRowMenu,
    points,
    handleRightClick,
    handleOver,
    handleLeave,
    handleSetRowMenu,
    handleClearClicked,
  } = useTableRow();

  const handleOpenFile = () => {
    dispatch(setSentDoc(null));
    localStorage.removeItem("instantJSON");
    id
      ? navigate(`${PATHES.SENT_DOCUMENT}/${id}`)
      : navigate(`${PATHES.SENT_DOCUMENT}/${selectedSentItems[0].id}`);
  };

  const isSelected = selectedSentItems.some((el) => el.id === id);
  const isEveryInProgress = selectedSentItems.every(
    (el) => el.status === "IN_PROGRESS",
  );
  const mainActionItems =
    signingsStatus &&
    getSigningsItems({
      selectedItemsCount: 1,
      signingsType,
      signingsStatus,
    });
  const additionalActionItems =
    !(isEveryInProgress && !signingsStatus) &&
    !(status === "IN_PROGRESS" && !signingsStatus) &&
    getSigningsItems({
      selectedItemsCount: 1,
      signingsType,
      signingsStatus,
      isAdditional: true,
      isAllSelectedItemsInProgress: isEveryInProgress,
    });
  const contextActionItems = !(status === "IN_PROGRESS" && !signingsStatus)
    ? getSigningsItems({
        selectedItemsCount: selectedSentItems.length,
        signingsType,
        signingsStatus,
        isAllShowed: true,
        isAllSelectedItemsInProgress: isEveryInProgress,
      })
    : [];

  return (
    <OutsideClickHandler onOutsideClick={handleClearClicked}>
      <div
        onContextMenu={handleRightClick}
        onMouseEnter={handleOver}
        onMouseLeave={handleLeave}
        onClick={onChange}
        className={cs([
          styles.SigningsSentTableRow,
          isSelected && styles.selected,
        ])}
      >
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <Checkbox name={id} onChange={onChange} isChecked={isSelected} />
        </div>
        <div className={cs([styles.td, styles.black])}>
          <div className={styles.name} onClick={handleOpenFile}>
            <DocImage type="PDF" previewUrl={previewFile?.url} />
            <div className={styles.textName}>{name}</div>
          </div>
          <RowActions
            additionalActions={additionalActionItems}
            actions={mainActionItems}
            isRowMenu={isRowMenu}
            points={points}
            handleSetRowMenu={handleSetRowMenu}
            handleLeave={handleLeave}
            id={id}
            isShowed={isHovered}
            signingsType={signingsType}
            signingsStatus={signingsStatus}
          />
        </div>
        <div className={styles.td}>
          <div className={cs([styles.status, styles[status]])}>
            {SIGNING_STATUSES[status]}{" "}
            {inProgressState &&
              `${inProgressState.signed}/${inProgressState.total}`}
          </div>
        </div>
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <DocIDCopy id={id} size="sm" />
        </div>
        <div className={styles.td}>
          {dayjs(sentAt).format(`${dateFormat}, ${timeFormat}`)}
        </div>
        {isClicked && contextActionItems?.length > 0 && (
          <ContextMenu
            theme="light"
            items={contextActionItems}
            top={points.y}
            left={points.x}
            isFixed
            id={id}
            onClose={handleClearClicked}
            signingsType={signingsType}
            signingsStatus={signingsStatus}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
