import { FC } from "react";

import { cs } from "utils";

import signIcon from "assets/img/pdfView/sign.svg";
import eraseIcon from "assets/img/pdfView/erase.svg";

import styles from "./styles.module.scss";

type CounterInfoProps = {
  totalSigned: number;
  totalWidgets: number;
  handleSignAll?: () => void;
  handleCleanAll?: () => void;
  isSignAllDisabled: boolean;
};

export const CounterInfo: FC<CounterInfoProps> = ({
  totalSigned,
  totalWidgets,
  isSignAllDisabled,
  handleSignAll = () => {},
  handleCleanAll = () => {},
}) => {
  const isAllSigned = totalWidgets > 0 && totalWidgets === totalSigned;
  return (
    <nav className={styles.CounterInfo}>
      <div
        onClick={() => {
          !isSignAllDisabled && handleSignAll();
        }}
        className={cs([styles.button, isSignAllDisabled && styles.disabled])}
      >
        <img src={signIcon} alt="sign" />
        Sign all boxes
      </div>
      <div
        onClick={() => {
          totalSigned !== 0 && handleCleanAll();
        }}
        className={cs([styles.button, totalSigned === 0 && styles.disabled])}
      >
        <img src={eraseIcon} alt="clear" />
        Clear all boxes
      </div>
      <div className={styles.statusWrap}>
        <div className={cs([styles.status, isAllSigned && styles.completed])}>
          Signed {totalSigned}/{totalWidgets}
        </div>
      </div>
    </nav>
  );
};
