import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetFilesQuotaResponse } from "api/Files";
import { IGlobalSearchDoc } from "types";
import { getQuota, getMetadata, getGlobalSearchItems } from "./thunks";

interface IErrorModal {
  title: string;
  text: string;
  submitButtonTitle?: string;
  onSubmit: () => void;
  onCancel: () => void;
}

interface IOpenedDocument {
  id: string;
  name: string;
  url: string;
  modifiedAt?: string;
  type?: string;
  sharedDocumentId?: string;
}
interface IOpenedDocumentMetadata {
  documentId: string;
  certifiedAt: string;
  signedBy: string;
  reason: string;
  certificateIssuedBy: string;
}

export type DashboardState = {
  sidebarWidth: number;
  movingProgress: number;
  customSignature: string;
  isCustomSignatureModal: boolean;
  isLoading: boolean;
  search: string;
  globalSearchItems: IGlobalSearchDoc[];
  selectedSearchItems: IGlobalSearchDoc[];
  quota: IGetFilesQuotaResponse;
  errorModal: IErrorModal | null;
  dateFormat: string;
  timeFormat: string;
  language: string;
  openedDocument: IOpenedDocument | null;
  openedDocMetadata: IOpenedDocumentMetadata | null;
  isViewHistoryModal?: boolean;
};

const initialState: DashboardState = {
  sidebarWidth: 240,
  movingProgress: 0,
  customSignature: "",
  isCustomSignatureModal: false,
  isLoading: false,
  search: "",
  globalSearchItems: [],
  selectedSearchItems: [],
  quota: {
    usedQuota: 0,
    unusedQuota: 0,
    totalQuota: 209715200,
    generalFreemiumQuota: 0,
    generalPremiumQuota: 0,
  },
  errorModal: null,
  dateFormat: localStorage.getItem("dateFormat") || "MMMM D, YYYY",
  timeFormat: localStorage.getItem("timeFormat") || "hh:mm a",
  language: localStorage.getItem("lng") || "en",
  openedDocument: { url: "", id: "", name: "" },
  openedDocMetadata: null,
  isViewHistoryModal: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setSidebarWidth(state, { payload }: PayloadAction<number>) {
      state.sidebarWidth = payload;
    },
    setMovingProgress(state, { payload }: PayloadAction<number>) {
      state.movingProgress = payload;
    },
    setCustomSignature(state, { payload }: PayloadAction<string>) {
      state.customSignature = payload;
    },
    setIsCustomSignatureModal(state, { payload }: PayloadAction<boolean>) {
      state.isCustomSignatureModal = payload;
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setQuota(state, { payload }: PayloadAction<IGetFilesQuotaResponse>) {
      state.quota = payload;
    },
    setErrorModal(state, { payload }: PayloadAction<IErrorModal | null>) {
      state.errorModal = payload;
    },
    setDateFormat(state, { payload }: PayloadAction<string>) {
      state.dateFormat = payload;
    },
    setTimeFormat(state, { payload }: PayloadAction<string>) {
      state.timeFormat = payload;
    },
    setLanguage(state, { payload }: PayloadAction<string>) {
      state.language = payload;
    },
    setOpenedDocument(
      state,
      { payload }: PayloadAction<IOpenedDocument | null>,
    ) {
      state.openedDocument = payload;
    },
    setOpenedDocMetadata(
      state,
      { payload }: PayloadAction<IOpenedDocumentMetadata | null>,
    ) {
      state.openedDocMetadata = payload;
    },
    setGlobalSearchItems(
      state,
      { payload }: PayloadAction<IGlobalSearchDoc[]>,
    ) {
      state.globalSearchItems = payload;
    },
    setSelectedSearchItems(
      state,
      { payload }: PayloadAction<IGlobalSearchDoc[]>,
    ) {
      state.selectedSearchItems = payload;
    },
    setIsViewHistoryModal(state, { payload }: PayloadAction<boolean>) {
      state.isViewHistoryModal = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuota.fulfilled, (state, action) => {
      if (action.payload) {
        state.quota = action.payload;
      }
    });
    builder.addCase(getMetadata.fulfilled, (state, action) => {
      if (action.payload) {
        state.openedDocMetadata = action.payload;
      }
    });
    builder.addCase(getGlobalSearchItems.fulfilled, (state, action) => {
      if (action.payload) {
        state.globalSearchItems = action.payload.items;
      }
    });
  },
});

export const {
  setSidebarWidth,
  setMovingProgress,
  setCustomSignature,
  setIsCustomSignatureModal,
  setIsLoading,
  setSearch,
  setErrorModal,
  setDateFormat,
  setTimeFormat,
  setOpenedDocument,
  setLanguage,
  setOpenedDocMetadata,
  setGlobalSearchItems,
  setSelectedSearchItems,
  setIsViewHistoryModal,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
