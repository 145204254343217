import { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHES } from "constants/pathes";

import { useAppDispatch, useAppSelector, settings, dashboard } from "store";
import {
  setSubscriptionRequiredModal,
  setNameRequiredModal,
} from "store/subscription";

import { AccountButton, GlobalSearchTable, GlobalSearch } from "components";
import { Button } from "components/UI";

import styles from "./styles.module.scss";

export const Signings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("Pages", {
    keyPrefix: "Signings",
  });
  const { user } = useAppSelector(settings);
  const { search } = useAppSelector(dashboard);

  useEffect(() => {
    if (user && !(user?.isPremium || user?.isWebPremium)) {
      navigate(PATHES.HOME);
      dispatch(setSubscriptionRequiredModal(true));
    }
  }, [dispatch, navigate, user]);

  const handleRequestSigning = () => {
    if (!user?.isWebPremium) {
      dispatch(setSubscriptionRequiredModal(true));
      return;
    }
    if (!user.firstName || !user.lastName) {
      dispatch(setNameRequiredModal(true));
      return;
    }
    navigate(PATHES.FILE_RECIPIENTS);
  };

  return (
    <div className={styles.Signings}>
      <header className={styles.header}>
        <GlobalSearch />
        <div className={styles.rightSide}>
          <Button
            variant="primary"
            title={t("requestButtonTitle")}
            onClick={handleRequestSigning}
            className={styles.reqestButton}
          />
          <AccountButton />
        </div>
      </header>
      {!search && (
        <ul className={styles.links}>
          <li>
            <NavLink
              to={PATHES.INBOX}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              {t("inbox")}
            </NavLink>
          </li>
          {user?.isWebPremium && (
            <li>
              <NavLink
                to={PATHES.SENT}
                className={({ isActive }) =>
                  [styles.link, isActive ? styles.active : ""].join(" ")
                }
              >
                {t("sent")}
              </NavLink>
            </li>
          )}
          {user?.isWebPremium && (
            <li>
              <NavLink
                to={PATHES.DRAFT}
                className={({ isActive }) =>
                  [styles.link, isActive ? styles.active : ""].join(" ")
                }
              >
                {t("draft")}
              </NavLink>
            </li>
          )}
        </ul>
      )}
      {search ? (
        <div className={styles.searchWrap}>
          <GlobalSearchTable />{" "}
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};
