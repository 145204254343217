import { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector, signings } from "store";
import { setIsViewHistoryModal } from "store/dashboard";

import { Icon } from "components/UI";

import { cs } from "utils";
import { SIGNING_INBOX_STATUSES } from "types";

import styles from "./styles.module.scss";

type RecipientsWidgetProps = {
  isMobileMenu?: boolean;
  isGuest?: boolean;
};

export const RecipientsWidget: FC<RecipientsWidgetProps> = ({
  isMobileMenu,
  isGuest,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "RecipientsWidget",
  });
  const { sentDocId, linkId, ownerLinkId } = useParams();
  const { inboxDoc, sentDoc, guestRecipientDoc, guestOwnerDoc } =
    useAppSelector(signings);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [isResizer, setIsResizer] = useState<boolean>(false);
  let docToSign = inboxDoc;
  if (sentDocId) {
    docToSign = sentDoc;
  } else if (linkId) {
    docToSign = guestRecipientDoc;
  } else if (ownerLinkId) {
    docToSign = guestOwnerDoc;
  }

  if (docToSign?.recipients?.length === 0) {
    return <></>;
  }

  return (
    <nav
      className={cs([
        styles.RecipientsWidget,
        isMinimized && styles.minimized,
        docToSign?.status === "REJECTED" && !isGuest && styles.rejected,
        isMobileMenu && styles.relative,
      ])}
    >
      {/* <Modal isShowed={isActionsHistory} styleWrap={{ zIndex: 110 }}>
        <ActionsHistoryModal
          onClose={() => setIsActionsHistory(false)}
          id={docToSign?.id || ""}
          docName={docToSign?.name || ""}
          isGuest={!!(linkId || ownerLinkId)}
        />
      </Modal> */}
      <div
        className={cs([styles.resizer, isResizer && styles.visible])}
        onClick={() => setIsMinimized((prevState) => !prevState)}
        onMouseEnter={() => setIsResizer(true)}
        onMouseLeave={() => setIsResizer(false)}
      >
        <Icon
          name="chevron-left-empty"
          size={20}
          className={cs([styles.arrowIcon, isMinimized && styles.rotated])}
        />
      </div>
      <div className={styles.recipients}>
        {!isMinimized && <h2 className={styles.title}>{t("recipients")}:</h2>}
        <ul
          className={cs([
            styles.recipientsList,
            isMinimized && styles.minimized,
            isMobileMenu && styles.relative,
          ])}
        >
          {docToSign?.recipients?.map((item) => (
            <li className={styles.recipient} key={item.email}>
              <div
                className={cs([styles.card, isMinimized && styles.minimized])}
                style={{
                  backgroundColor: item.color,
                }}
              >
                <div className={styles.name}>{item.name}</div>
                {!isMinimized && (
                  <div className={styles.email} title={item.email}>
                    {item.email}
                  </div>
                )}
              </div>
              <div className={cs([styles.status, styles[item.status]])}>
                {SIGNING_INBOX_STATUSES[item.status]}
              </div>
            </li>
          ))}
        </ul>
        <div
          className={cs([
            styles.actionsHistory,
            isMobileMenu && styles.relative,
          ])}
          onClick={() => dispatch(setIsViewHistoryModal(true))}
        >
          <Icon name="info" size={14} />
          {!isMinimized && (
            <div className={styles.actionsHistoryText}>
              {t("actionsHistory")}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
