import { TParticipant, TSharedDocumentStatus } from "types";

interface IGetSigningType {
  participantType?: TParticipant;
  status?: TSharedDocumentStatus;
}
export const getSigningType = ({
  participantType,
  status,
}: IGetSigningType) => {
  if (participantType === "RECIPIENT" && status !== "DRAFT") {
    return "inbox";
  }
  if (participantType === "OWNER" && status !== "DRAFT") {
    return "sent";
  }
  if (status === "DRAFT") {
    return "draft";
  }
  return undefined;
};
