import {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  useMemo,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, folders, trashBin } from "store";
import { setDestinationFolder, setSelectedItems } from "store/folders";
import { getFolder } from "store/folders/thunks";

import { FilterType, NoTableData, BreadcrumbsDocs } from "components";
import { Checkbox, Icon } from "components/UI";
import { cs, getMenuItems, getMenuTitle } from "utils";
import { SelectedType, DataType, IParent } from "types";
import { useDropzoneFlow, useActions } from "hooks";

import { HeaderCell } from "../HeaderCell";
// import { MyDocsTableModals } from "./Modals";
import { MyDocsTableRow } from "./Row";
import styles from "./styles.module.scss";

type FieldSorted = "name" | "modifiedAt";

export const MyDocsTable: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "MyDocs",
  });
  const { t: tG } = useTranslation("General");
  const { renderDropzone, isDragActive, getRootProps, UploadModalWrapped } =
    useDropzoneFlow();
  const {
    currentFolder,
    allGeneralItems,
    selectedItems,
    createdFolderId,
    // softDeletedItems,
    // isDeleteItemsModal,
    // isMovingItemsModal,
  } = useAppSelector(folders);
  // const { search } = useAppSelector(dashboard);
  const { itemsToRestore } = useAppSelector(trashBin);
  // const [searchValue] = useDebounce(search, 1000);
  const { getAction } = useActions();
  const [tableData, setTableData] = useState<IParent[]>([]);
  const storageSorting = localStorage.getItem("sorting");
  const parsedSorting = storageSorting && JSON.parse(storageSorting);
  const [sorting, setSorting] = useState({
    name: parsedSorting?.name === true || false,
    modifiedAt: parsedSorting?.modifiedAt === true || false,
  });
  const [lastSorted, setLastSorted] = useState<FieldSorted>(
    parsedSorting?.lastSorted || "modifiedAt",
  );
  // const initialFilterTypes: SelectedType[] = ["FOLDER", "PDF_CERTIFIED", "PDF"];
  const [selectedTypes, setSelectedTypes] = useState<SelectedType[]>([]);
  const openedFolderData = useMemo(
    () =>
      allGeneralItems.find((item) => item.folder?.id === currentFolder)
        ?.items || [],
    [allGeneralItems, currentFolder],
  );

  const initialData = useMemo(() => openedFolderData || [], [openedFolderData]);

  const sortTable = useCallback(() => {
    if (tableData.length > 0) {
      setTableData((prevState) =>
        prevState.slice().sort((a: DataType, b: DataType) => {
          let asc = sorting[lastSorted] ? 1 : -1;
          let desc = sorting[lastSorted] ? -1 : 1;

          if (lastSorted === "modifiedAt") {
            asc = sorting[lastSorted] ? -1 : 1;
            desc = sorting[lastSorted] ? 1 : -1;
          }

          return a[lastSorted]?.toLocaleLowerCase() <
            b[lastSorted]?.toLocaleLowerCase()
            ? asc
            : desc;
        }),
      );
    }
  }, [sorting, lastSorted, tableData.length]);

  const handleSort = useCallback(
    (field: FieldSorted) => {
      localStorage.setItem(
        "sorting",
        JSON.stringify({
          ...parsedSorting,
          [field]: !sorting[field],
          lastSorted: field,
        }),
      );
      setLastSorted(field);
      setSorting((prevState) => ({
        ...prevState,
        [field]: !prevState[field],
      }));
      // sortTable(field);
    },
    [parsedSorting, sorting],
  );

  useEffect(() => {
    createdFolderId && dispatch(getFolder({ id: createdFolderId }));
  }, [createdFolderId, dispatch]);

  useEffect(() => {
    if (currentFolder) {
      dispatch(setSelectedItems([]));
      dispatch(getFolder({ id: currentFolder }));
    }
  }, [dispatch, currentFolder, itemsToRestore]);

  // useEffect(() => {
  //   openedFolderData && setTableData(openedFolderData);
  // }, [initialData, openedFolderData]);

  useEffect(() => {
    sortTable();
  }, [sortTable]);

  // const searchItems = useCallback(async () => {
  //   if (searchValue) {
  //     try {
  //       const res = await Folders.searchFolder({ query: searchValue });
  //       if (res?.items) {
  //         setTableData(res.items);
  //       }
  //     } catch (error) {
  //       console.log("error:", error);
  //       if (isAxiosError(error)) {
  //         error?.message &&
  //           toastError(
  //             Array.isArray(error.message) ? error.message[0] : error.message,
  //           );
  //       }
  //     }
  //   } else {
  //     setTableData(openedFolderData);
  //     sortTable();
  //   }
  // }, [searchValue, openedFolderData, sortTable]);

  useEffect(() => {
    setTableData(openedFolderData);
    sortTable();
    // searchItems();
  }, [openedFolderData, sortTable]);

  const handleClearSelected = useCallback(() => {
    dispatch(setSelectedItems([]));
  }, [dispatch]);

  useEffect(() => {
    return handleClearSelected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toast.dismiss({ containerId: "Undo" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolder]);

  const handleSelectAll = () => {
    dispatch(
      setSelectedItems(
        selectedItems.length < initialData?.length ? [...initialData] : [],
      ),
    );
  };

  const handleSelectItem = (item: DataType) => {
    dispatch(
      setSelectedItems(
        selectedItems.some((el) => el.id === item.id)
          ? [...selectedItems.filter((el) => el.id !== item.id)]
          : [...selectedItems, item],
      ),
    );
  };

  const handleFilter = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    const newType = name as SelectedType;
    setSelectedTypes((prevState) =>
      prevState.includes(newType)
        ? [...prevState.filter((el) => el !== newType)]
        : [newType],
    );
  };

  const selectAllTypes = () => {
    setSelectedTypes(
      // selectedTypes.length < initialFilterTypes.length
      //   ? initialFilterTypes
      //   : [],
      [],
    );
  };

  const handleDragOver = ({
    id,
    name,
    type,
  }: {
    id: string;
    name: string;
    type: SelectedType;
  }) => {
    if (type === "FOLDER") {
      dispatch(setDestinationFolder({ id, title: name }));
    }
  };

  const filteredData = useMemo(
    () =>
      tableData
        ?.filter(
          (el) => selectedTypes.length === 0 || selectedTypes.includes(el.type),
        )
        // ?.filter(
        //   (el) =>
        //     !softDeletedItems.includes(el.id) ||
        //     isDeleteItemsModal ||
        //     isMovingItemsModal,
        // )
        .sort((a: DataType, b: DataType) => {
          return a.type === "FOLDER" && b.type !== "FOLDER" ? -1 : 1;
        }),
    [selectedTypes, tableData],
  );

  return (
    <div className={styles.MyDocsTable} {...getRootProps()}>
      <UploadModalWrapped />
      {/* <MyDocsTableModals /> */}
      {/* {searchValue ? (
        <h2 className={styles.title}>
          {filteredData.length
            ? t("results", { count: filteredData.length })
            : t("noResults")}
        </h2>
      ) : ( */}
      <BreadcrumbsDocs className={styles.title} sliceSource="folders" />
      {/* )} */}
      <header
        className={cs([styles.tr, selectedItems.length > 0 && styles.active])}
      >
        <Checkbox
          name={tG("all")}
          onChange={handleSelectAll}
          isChecked={
            selectedItems.length === initialData?.length &&
            selectedItems.length > 0
          }
        />
        {selectedItems.length > 0 ? (
          <ul className={styles.actions}>
            {getMenuItems({ selectedItems })?.map((el, index) => (
              <li
                className={styles.action}
                key={index}
                onClick={getAction({ name: el })}
              >
                <Icon name={el} size={18} /> {getMenuTitle({ name: el })}
              </li>
            ))}
          </ul>
        ) : (
          <>
            <HeaderCell
              onSort={() => handleSort("name")}
              isSorted={sorting.name}
              name={tG("name")}
            />
            <div className={styles.th}>
              <FilterType
                onChange={handleFilter}
                selectAll={selectAllTypes}
                selectedTypes={selectedTypes}
              />
            </div>
            <HeaderCell name={t("attributes")} />
            <HeaderCell
              name={t("modificationDate")}
              onSort={() => handleSort("modifiedAt")}
              isSorted={sorting.modifiedAt}
            />
          </>
        )}
      </header>
      {!isDragActive && (
        <>
          {filteredData?.map((item, index) => (
            <div
              key={item.id}
              style={
                index === filteredData.length - 1
                  ? { marginBottom: "5rem" }
                  : {}
              }
              onMouseEnter={() =>
                handleDragOver({
                  id: item.id,
                  name: item.name,
                  type: item.type,
                })
              }
              onMouseLeave={() => dispatch(setDestinationFolder(null))}
            >
              <MyDocsTableRow
                onChange={() => handleSelectItem(item)}
                item={item}
              />
            </div>
          ))}

          <NoTableData
            // isSearch={!!searchValue}
            isNoFilteredData={filteredData?.length === 0}
            isNoData={
              openedFolderData &&
              // initialData?.filter(
              //   (el) => !softDeletedItems.includes(el.id) || isDeleteItemsModal,
              // ).length === 0
              initialData?.length === 0
            }
            selectedTypes={selectedTypes}
          />
        </>
      )}
      {renderDropzone()}
    </div>
  );
};
