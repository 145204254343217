import { FolderNode, FolderItem } from "types";

export function calculateParentsCount(
  folderNode: FolderNode,
  count: number,
): void {
  folderNode.folder.parentsCount = count;
  folderNode.childFolders.forEach((child) => {
    calculateParentsCount(child, count + 1);
  });
}

export function transformData(data: any[]): FolderNode[] {
  const folderNodes: FolderNode[] = [];
  const foldersMap: { [id: string]: FolderNode } = {};

  // First pass: create folder nodes and populate foldersMap
  data.forEach((item) => {
    const folderNode: FolderNode = {
      folder: { ...item.folder, parentsCount: 0 },
      parentFolder: item.parentFolder,
      items: item.items.map((item: FolderItem) => ({
        ...item,
        parentsCount: 0,
      })),
      childFolders: [],
    };

    foldersMap[item.folder?.id] = folderNode;

    if (!item.parentFolder) {
      folderNodes.push(folderNode);
    }
  });

  // Second pass: populate childFolders of each folder node
  data
    .slice()
    .sort((a, b) => (a.folder?.createdAt > b.folder?.createdAt ? 1 : -1))
    .forEach((item) => {
      const { parentFolderId } = item.folder || {};
      if (parentFolderId && foldersMap[parentFolderId]) {
        const parentNode = foldersMap[parentFolderId];
        const childFolderNode = foldersMap[item.folder.id];
        parentNode.childFolders.push(childFolderNode);

        // Add items of the parent folder to the childFolders as well
        parentNode.items.forEach((parentItem) => {
          childFolderNode.items.push(parentItem);
        });
      }
    });

  // Calculate parentsCount recursively starting from root folders
  folderNodes.forEach((root) => {
    calculateParentsCount(root, 0);
  });

  return folderNodes;
}
