import { FC, ChangeEvent, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch, requestSigning } from "store";
import { setSearch, setIsSearch, setSelectedItems } from "store/requestSigning";
import { PDF } from "api";

import { SelectFromMyDocsTable } from "components";
import { Search, Button, Spinner } from "components/UI";
import { palette, cs } from "utils";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type SelectFromMyDocsModalProps = {
  onClose: () => void;
  onUpload: (acceptedFiles: File[], id?: string[]) => void;
};

export const SelectFromMyDocsModal: FC<SelectFromMyDocsModalProps> = ({
  onClose,
  onUpload,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Modals", { keyPrefix: "SelectFromMyDocs" });
  const { search, selectedItems } = useAppSelector(requestSigning);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
  };

  const handleClose = () => {
    onClose();
    dispatch(setSelectedItems([]));
  };

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const selectedFilesToUpload = await Promise.all(
        selectedItems.map(async (item) => {
          const res = await PDF.getPdfDocument(item.id);
          const { url = "", name = "" } = res?.document || {};
          const s3Res = await axios.get(url, { responseType: "blob" });
          const uploadedFile = await s3Res.data;
          return new File([uploadedFile], name, {
            type: "application/pdf",
          });
        }),
      );

      selectedFilesToUpload &&
        onUpload(
          selectedFilesToUpload,
          selectedItems.map((el) => el.id),
        );
      handleClose();
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSearch = () => {
    dispatch(setSearch(""));
    dispatch(setIsSearch(false));
  };

  const submitButtonText =
    selectedItems.length > 1
      ? t("submitButtonTitlePlural", {
          itemsCount: `${selectedItems.length}`,
        })
      : t("submitButtonTitle", {
          itemsCount: `${selectedItems.length || ""}`,
        });

  return (
    <div className={styles.SelectFromMyDocsModal}>
      <ModalHeader onClose={handleClose} title={t("title")} />
      <div className={cs([styles.searchField, search && styles.withButton])}>
        <Search
          className={styles.search}
          onChange={handleSearch}
          value={search}
          onClear={() => dispatch(setSearch(""))}
        />
        {search && (
          <Button
            variant="textBlack"
            size="sm"
            title={t("cancelButtonTitle")}
            onClick={handleCancelSearch}
            className={styles.cancelButton}
          />
        )}
      </div>
      <SelectFromMyDocsTable />
      <Button
        variant="primary"
        size="lg"
        title={isLoading ? <Spinner color={palette.white} /> : submitButtonText}
        isDisabled={selectedItems.length === 0}
        onClick={handleSubmit}
        className={styles.submitButton}
      />
    </div>
  );
};
