export type TAction =
  | { type: "SET_IS_MOBILE"; payload: boolean }
  | { type: "SET_IS_TABLET"; payload: boolean };

export const setIsMobile = (isMobile: boolean): TAction => ({
  type: "SET_IS_MOBILE",
  payload: isMobile,
});

export const setIsTablet = (isTablet: boolean): TAction => ({
  type: "SET_IS_TABLET",
  payload: isTablet,
});
