import { FC } from "react";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";

import { useAppSelector, signings, dashboard } from "store";

import { ContextMenu, RowActions, DocImage, DocIDCopy } from "components";
import { Checkbox } from "components/UI";

import { cs, getSigningsItems } from "utils";
import { IDraft } from "types";
import { useTableRow } from "hooks";

import styles from "./styles.module.scss";

interface ISigningsTableRowProps {
  onChange: () => void;
  item: IDraft;
}

export const SigningsDraftTableRow: FC<ISigningsTableRowProps> = ({
  onChange,
  item,
}) => {
  const { status, previewFile, id, name, modifiedAt } = item;
  const { selectedDraftItems } = useAppSelector(signings);
  const { dateFormat, timeFormat } = useAppSelector(dashboard);

  const {
    isClicked,
    isHovered,
    isRowMenu,
    points,
    handleRightClick,
    handleOver,
    handleLeave,
    handleSetRowMenu,
    handleClearClicked,
  } = useTableRow();

  const isSelected = selectedDraftItems.some((el) => el.id === id);

  return (
    <OutsideClickHandler onOutsideClick={handleClearClicked}>
      <div
        onContextMenu={handleRightClick}
        onMouseEnter={handleOver}
        onMouseLeave={handleLeave}
        onClick={onChange}
        className={cs([
          styles.SigningsDraftTableRow,
          isSelected && styles.selected,
        ])}
      >
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <Checkbox name={id} onChange={onChange} isChecked={isSelected} />
        </div>
        <div className={cs([styles.td, styles.black])}>
          <div className={styles.name}>
            <DocImage type="PDF" previewUrl={previewFile?.url} />
            <div className={styles.textName}>{name}</div>
          </div>
          <RowActions
            additionalActions={getSigningsItems({
              selectedItemsCount: 1,
              signingsType: "draft",
              isAdditional: true,
            })}
            actions={getSigningsItems({
              selectedItemsCount: 1,
              signingsType: "draft",
            })}
            isRowMenu={isRowMenu}
            points={points}
            handleSetRowMenu={handleSetRowMenu}
            handleLeave={handleLeave}
            id={id}
            isShowed={isHovered}
            signingsType="draft"
          />
        </div>
        <div className={styles.td}>
          <div className={cs([styles.status, styles.DRAFT])}>{status}</div>
        </div>
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <DocIDCopy id={id} size="sm" />
        </div>
        <div className={styles.td}>
          {dayjs(modifiedAt).format(`${dateFormat}, ${timeFormat}`)}
        </div>
        {isClicked && (
          <ContextMenu
            theme="light"
            items={getSigningsItems({
              selectedItemsCount: selectedDraftItems.length,
              signingsType: "draft",
            })}
            top={points.y}
            left={points.x}
            isFixed
            id={id}
            onClose={handleClearClicked}
            signingsType="draft"
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
