import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Signatures,
  ICreateSignatureRequest,
  IEditSignatureRequest,
  ICreateGuestSignatureRequest,
  IEditGuestSignatureRequest,
  IDeleteGuestSignatureRequest,
} from "api";

export const getSignatures = createAsyncThunk(
  "signatures/getSignatures",
  async () => Signatures.getSignatures(),
);

export const createSignature = createAsyncThunk(
  "signatures/createSignature",
  async (data: ICreateSignatureRequest) => Signatures.createSignature(data),
);

export const putSignature = createAsyncThunk(
  "signatures/putSignature",
  async (data: IEditSignatureRequest) => Signatures.putSignature(data),
);

export const deleteSignature = createAsyncThunk(
  "signatures/deleteSignature",
  async (id: string) => Signatures.deleteSignature(id),
);

export const getGuestSignatures = createAsyncThunk(
  "signatures/getGuestSignatures",
  async (id: string) => Signatures.getGuestSignatures(id),
);

export const createGuestSignature = createAsyncThunk(
  "signatures/createGuestSignature",
  async (data: ICreateGuestSignatureRequest) =>
    Signatures.createGuestSignature(data),
);

export const putGuestSignature = createAsyncThunk(
  "signatures/putGuestSignature",
  async (data: IEditGuestSignatureRequest) =>
    Signatures.putGuestSignature(data),
);

export const deleteGuestSignature = createAsyncThunk(
  "signatures/deleteGuestSignature",
  async (data: IDeleteGuestSignatureRequest) =>
    Signatures.deleteGuestSignature(data),
);
