import { FC, useRef, useCallback, useEffect } from "react";
import { Annotation, Instance } from "pspdfkit";
import { useParams, useNavigate } from "react-router-dom";

// import { REACT_APP_PSPDFKIT_ID } from "constants/index"; // update to env secret
import { setDestinationFolder } from "store/folders";
import { setStoredInstance } from "store/signatures";
import { setIsLoading } from "store/dashboard";
import { setIsDetailedViewMenu } from "store/signings";
import {
  setPremiumRequiredModal,
  setNameRequiredModal,
} from "store/subscription";

import { useAppDispatch, useAppSelector, dashboard, settings } from "store";

import movefolderIcon from "assets/img/pdfView/moveFolder.svg";
import certifyIcon from "assets/img/pdfView/certification.svg";
import certifyPremiumIcon from "assets/img/pdfView/certificationPremium.svg";
import signIcon from "assets/img/icons/sign.svg";
import downloadIcon from "assets/img/icons/download.svg";

import { getButtonNode, getEmptyNode, palette, downloadPdf } from "utils";
import { useActions, useUploadRequest } from "hooks";
import { PATHES } from "constants/pathes";

type PdfViewerDetailedViewProps = {
  isCertified?: boolean;
};

export const PdfViewerDetailedView: FC<PdfViewerDetailedViewProps> = ({
  isCertified,
}) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef(null);
  const { openedDocument } = useAppSelector(dashboard);
  const { user } = useAppSelector(settings);
  const navigate = useNavigate();
  const {
    uploadFiles,
    handleUpdateFile,
    getFileFromInstance,
    handleAddWaterMark,
  } = useUploadRequest();
  // const { isPremium, isTrial } = useSubscription();

  const isLocal = window.location.href.startsWith("http://localhost:");
  const { documentUserID } = useParams();
  const { handleMove, handleCertify } = useActions(documentUserID);

  const certifyIconNode = user?.isPremium
    ? getButtonNode("Certify", certifyPremiumIcon)
    : getButtonNode("Certify", certifyIcon);

  const onCertify = useCallback(() => {
    if (user) {
      handleCertify();
    }
  }, [handleCertify, user]);

  const handleAddFileToRequest = useCallback(
    async (instance: Instance) => {
      if (!user?.isWebPremium) {
        dispatch(setPremiumRequiredModal(true));
        return;
      }
      if (!user.firstName || !user.lastName) {
        dispatch(setNameRequiredModal(true));
        return;
      }
      dispatch(setIsLoading(true));
      const editedPDF =
        openedDocument?.name &&
        (await getFileFromInstance(instance, openedDocument.name));
      const selectedFileToUpload =
        editedPDF &&
        documentUserID &&
        (await handleUpdateFile(editedPDF, documentUserID));
      selectedFileToUpload &&
        documentUserID &&
        uploadFiles([selectedFileToUpload], [documentUserID]);
      dispatch(setIsLoading(false));
      navigate(PATHES.FILE_RECIPIENTS);
    },
    [
      documentUserID,
      handleUpdateFile,
      navigate,
      openedDocument,
      uploadFiles,
      getFileFromInstance,
      dispatch,
      user,
    ],
  );

  useEffect(() => {
    if (openedDocument?.url) {
      const container = containerRef.current; // This `useRef` instance will render the PDF.

      let PSPDFKit: any;
      let instance: Instance;

      (async function createContainer() {
        PSPDFKit = await import("pspdfkit");

        // View state config
        const initialViewState = new PSPDFKit.ViewState({
          scrollMode: PSPDFKit.ScrollMode.CONTINUOUS,
          layoutMode: PSPDFKit.LayoutMode.SINGLE,
          pageSpacing: 10,
          spreadSpacing: 50,
        });

        const moveButton = {
          type: "custom",
          id: "moveToFolder",
          title: "Move to another folder",
          icon: movefolderIcon,
          onPress: async () => {
            dispatch(setDestinationFolder(null));
            handleMove();
          },
        };

        const requestSigningButton = {
          type: "custom",
          id: "requestSigning",
          title: "Request signing",
          node: getButtonNode("Request signing", signIcon),
          onPress: () => handleAddFileToRequest(instance),
        };

        const certifyButton = {
          type: "custom",
          id: "certify",
          title: "Certify",
          node: certifyIconNode,
          onPress: onCertify,
        };

        const exportButton = {
          type: "custom",
          id: "export",
          icon: downloadIcon,
          onPress: async () => {
            const buffer = await instance.exportPDF({ flatten: true });
            const updatedBuffer = await handleAddWaterMark(buffer);
            const blob = new Blob([user?.isPremium ? buffer : updatedBuffer], {
              type: "application/pdf",
            });
            const objectUrl = window.URL.createObjectURL(blob);
            downloadPdf(objectUrl, openedDocument.name);
          },
        };

        const emptyButton1 = {
          type: "custom",
          id: "emptyButton",
          node: getEmptyNode(1, `1px solid ${palette.grey30}`),
        };

        const emptyButton2 = {
          type: "custom",
          id: "emptyButton",
          node: getEmptyNode(3.7, `1px solid ${palette.grey80}`),
        };

        let toolbarItems = [];

        if (isCertified) {
          toolbarItems = [
            { type: "sidebar-thumbnails" },
            { type: "pager" },
            { type: "zoom-out" },
            { type: "zoom-in" },
            { type: "zoom-mode" },
            { type: "spacer" },
            { type: "search" },
            exportButton,
            { type: "print" },
          ];
          toolbarItems.push(moveButton);
        } else {
          toolbarItems = [
            { type: "sidebar-thumbnails" },
            { type: "pager" },
            { type: "zoom-out" },
            { type: "zoom-in" },
            { type: "zoom-mode" },
            { type: "spacer" },
            { type: "signature" },
            { type: "text" },
            { type: "line" },
            { type: "print" },
            { type: "document-editor" },
            { type: "search" },
            exportButton,
          ];
          toolbarItems.push(moveButton);
          toolbarItems.push(emptyButton1);
          toolbarItems.push({ type: "spacer" });
          toolbarItems.push(requestSigningButton);
          toolbarItems.push(certifyButton);
          toolbarItems.push(emptyButton2);
        }

        PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

        instance = await PSPDFKit.load({
          container,
          document: openedDocument?.url,
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
          initialViewState,
          toolbarItems,
          enableClipboardActions: true,
          enableHistory: true,
          locale: "en",
          styleSheets: ["/custom-pspdfkit.css"],
          licenseKey: isLocal ? "" : process.env.REACT_APP_PSPDFKIT_ID,
        });

        instance.addEventListener(
          "storedSignatures.create",
          async (annotation: Annotation) => {},
        );

        // instance.addEventListener("page.press", (event) => {
        //   dispatch(setIsDetailedViewMenu(false));
        // });

        instance.addEventListener("document.saveStateChange", async (event) => {
          if (event.hasUnsavedChanges) {
            dispatch(setStoredInstance(instance));
          }
        });

        return () => PSPDFKit && PSPDFKit.unload(container);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedDocument?.url]);

  return (
    <div
      onMouseEnter={() => {
        dispatch(setIsDetailedViewMenu(false));
      }}
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        zIndex: "100",
        position: "fixed",
        left: "0",
        top: isCertified ? "9.6rem" : "7.2rem",
        backgroundColor: "white",
      }}
    />
  );
};
