import { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAppSelector, signings } from "store";

import { Icon } from "components/UI";

import { cs } from "utils";

import styles from "./styles.module.scss";

type RejectionWidgetProps = {
  isMobileMenu?: boolean;
};

export const RejectionWidget: FC<RejectionWidgetProps> = ({ isMobileMenu }) => {
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "RejectionWidget",
  });
  const { inboxDoc, sentDoc } = useAppSelector(signings);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [isResizer, setIsResizer] = useState<boolean>(false);
  const { sentDocId } = useParams();
  let docToSign = inboxDoc;
  if (sentDocId) {
    docToSign = sentDoc;
  }

  if (docToSign?.recipients?.length === 0) {
    return <></>;
  }

  return (
    <nav
      className={cs([
        styles.RejectionWidget,
        isMinimized && styles.minimized,
        isMobileMenu && styles.relative,
      ])}
    >
      <div
        className={cs([styles.resizer, isResizer && styles.visible])}
        onClick={() => setIsMinimized((prevState) => !prevState)}
        onMouseEnter={() => setIsResizer(true)}
        onMouseLeave={() => setIsResizer(false)}
      >
        <Icon
          name="chevron-left-empty"
          size={20}
          className={cs([styles.arrowIcon, isMinimized && styles.rotated])}
        />
      </div>
      <h2 className={styles.title}>{t("title")}:</h2>
      <div className={styles.reasonText}>{docToSign?.rejection?.reason}</div>
    </nav>
  );
};
