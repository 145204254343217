import { useState, useEffect, useMemo, SyntheticEvent } from "react";
import { useDroppable } from "@dnd-kit/core";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  dashboard,
  settings,
  folders,
} from "store";
import { setCurrentFolder } from "store/folders";
import { setSearch } from "store/dashboard";
import { getFolder, getRootFolder } from "store/folders/thunks";
import { setSubscriptionRequiredModal } from "store/subscription";

import { PATHES } from "constants/pathes";
import { SUPPORT_LINK } from "constants/index";
import { Icon } from "components/UI";
import { TryPro, CloudMemory } from "components";

import mainLogo from "assets/img/main-logo.svg";
import { useResize } from "hooks";
import { transformData } from "utils";

import { SidebarFolder } from "./SidebarFolder";
import { SidebarFolders } from "./SidebarFolders";
import { SidebarModals } from "./SidebarModals";
import styles from "./styles.module.scss";

export const Sidebar = () => {
  const { t } = useTranslation("Sidebar");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sidebarWidth } = useAppSelector(dashboard);
  const { rootFolder, allGeneralItems, createdFolderId } =
    useAppSelector(folders);
  const { user } = useAppSelector(settings);
  const { node, isOver, setNodeRef } = useDroppable({
    id: "droppable",
  });
  const { sidebarRef, startResizing } = useResize();

  const [openedFolders, setOpenedFolders] = useState<string[]>([]);
  const [isOverRoot, setOverRoot] = useState<boolean>(false);
  const [isTip, setIsTip] = useState<boolean>(false);

  const newData = useMemo(
    () => transformData(allGeneralItems),
    [allGeneralItems],
  );

  useEffect(() => {
    if (isOver) {
      setOverRoot(true);
    } else {
      setOverRoot(false);
    }
  }, [isOver]);

  useEffect(() => {
    const sessionShowTip = sessionStorage.getItem("isCreateTip");
    if (allGeneralItems?.length === 2 && createdFolderId && !sessionShowTip) {
      setIsTip(true);
      sessionStorage.setItem("isCreateTip", "true");
    }
  }, [allGeneralItems, createdFolderId]);

  const handleOpenFolder = async (folder: string) => {
    const childFolders =
      allGeneralItems.find((item) => item.folder?.id === folder)?.items || [];

    if (!openedFolders.includes(folder)) {
      // dispatch(setIsLoading(true));
      await Promise.all(
        childFolders
          ?.filter((item) => item.type === "FOLDER")
          ?.map(async (item) => {
            // eslint-disable-next-line no-return-await
            return dispatch(getFolder({ id: item.id }));
          }),
      );
      // dispatch(setIsLoading(false));
    }

    setIsTip(false);
    setOpenedFolders((prevState) =>
      prevState.includes(folder)
        ? [...prevState.filter((el) => el !== folder)]
        : [...prevState, folder],
    );
  };

  const handleClearGlobalSearch = () => {
    dispatch(setSearch(""));
  };

  const handleClearCurrent = () => {
    dispatch(setCurrentFolder(""));
    handleClearGlobalSearch();
  };

  const handleOpenTrashBin = () => {
    dispatch(getRootFolder());
    handleClearGlobalSearch();
  };

  const handlePreventRedirect = (e: SyntheticEvent) => {
    handleClearGlobalSearch();
    if (!(user?.isPremium || user?.isWebPremium)) {
      e.preventDefault();
      dispatch(setSubscriptionRequiredModal(true));
    }
  };

  return (
    <>
      <SidebarModals />
      <nav
        className={styles.Sidebar}
        ref={sidebarRef}
        style={{ width: sidebarWidth }}
        onMouseDown={(e) => e.preventDefault()}
      >
        <div className={styles.resizer} onMouseDown={startResizing} />
        <main className={styles.main}>
          <NavLink
            to={PATHES.HOME}
            className={styles.mainLogo}
            onClick={handleClearGlobalSearch}
          >
            <img src={mainLogo} alt="main-logo" width="91" height="24" />
          </NavLink>
          <ul className={styles.list}>
            <li className={styles.navItem} onClick={handleClearCurrent}>
              <NavLink
                onClick={handlePreventRedirect}
                to={PATHES.SIGNINGS}
                className={({ isActive }) =>
                  [styles.link, isActive ? styles.active : ""].join(" ")
                }
              >
                <Icon name="sign" size={16} />
                {t("signings")}
              </NavLink>
            </li>
            <li className={styles.navItem} ref={setNodeRef}>
              {rootFolder && (
                <SidebarFolder
                  title={t("rootFolderName")}
                  id={rootFolder.id}
                  isOpened={openedFolders.includes(rootFolder.id)}
                  handleOpenFolder={() => handleOpenFolder(rootFolder.id)}
                  childrenCount={
                    allGeneralItems.find(
                      (item) => item.folder?.id === rootFolder?.id,
                    )?.items?.length
                  }
                  isTip={isTip}
                  setIsTip={setIsTip}
                  isRoot
                />
              )}
              <SidebarFolders
                handleOpenFolder={handleOpenFolder}
                openedFolders={openedFolders}
                isOverRoot={isOverRoot}
                rootName={node?.current?.outerText || ""}
                newData={newData}
              />
            </li>
            <li className={styles.navItem} onClick={handleOpenTrashBin}>
              <NavLink
                onClick={handleClearGlobalSearch}
                to={PATHES.TRASH}
                className={({ isActive }) =>
                  [styles.link, isActive ? styles.active : ""].join(" ")
                }
              >
                <Icon name="trash" size={16} />
                {t("trashBin")}
              </NavLink>
            </li>
            <li className={styles.navItem} onClick={handleClearCurrent}>
              <NavLink
                to={SUPPORT_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className={({ isActive }) =>
                  [styles.link, isActive ? styles.active : ""].join(" ")
                }
              >
                <Icon name="info" size={16} />
                {t("help")}
              </NavLink>
            </li>
          </ul>
          {!user?.isPremium && !user?.isWebPremium && (
            <TryPro onClick={() => navigate(PATHES.SUBSCRIPTION_PLANS)} />
          )}
        </main>
        <footer className={styles.footer}>
          <CloudMemory />
          <div className={styles.rights}>{t("allRights")} | 2024</div>
        </footer>
      </nav>
    </>
  );
};
